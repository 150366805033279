import { ref, createApp } from 'vue'
import { useNuxtApp, useCookie, useRouter } from '#app'
import { useAdvStat } from '@/composables/useAdvStat'
import type { AdvPackage, AdvAction, AdvBannerBox } from '@/types/adv'
import AdvBoxComponent from '@/components/adv/Box.vue'
// import VueLazyLoad from 'nuxt-lazy-load'

const list = ref<AdvPackage<AdvBannerBox>[]>([])
const listIgnore = ref<string[]>([])
const prepared = ref<AdvBannerBox[]>([])
const lastRow = ref(0)
const lastNum = ref(0)
const insertCount = ref(0)
const elements = ref<HTMLElement[]>([])

export function useAdvBox() {
  const cookieNameViewedPackages = 'ab_v_packages'
  const cookieNameViewedPackagesBanners = 'ab_v_packages_banners'

  function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  function isMobile() {
    return window.innerWidth <= 768
  }

  function getSection(): string {
    // const router = useRouter()
    // const path = router.currentRoute.value.fullPath.split('/')
    // return path[1] ? path[1] : 'index'
    if (typeof window === 'undefined') {
      return 'index'
    }  
    const segments = window.location.pathname.split('/').filter(Boolean)
    return segments[0] ?? 'index'
  }

  function setIgnore(l: string[]) {
    listIgnore.value = l
  }


  function isIgnorePages() {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath
    const ignore = [
      'diaries/[\\w-]+/edit/week',
      'grower/[\\w-]+/settings',
      'diaries/edit',
      'brands-manager',
    ].map((item) => new RegExp(item))
    for (const item of ignore) {
      if (item.test(path)) return true
    }
    return false
  }

  function isIgnoreSection() {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath
    if (!listIgnore.value) return false
    for (const ignore_section of listIgnore.value) {
      if (path.indexOf(ignore_section) >= 0) return true
    }
    return false
  }

  function clear() {
    for (const el of elements.value) {
      el.remove()
    }
    lastRow.value = 0
    lastNum.value = 0
    insertCount.value = 0
    elements.value = []
  }

  function isReadyApply() {
    if (useDisabledAdv().value) return null
    return (
      !!document.querySelector('.report_boxs') &&
      !!document.querySelector('.report_box_item')
    )
  }

  function countInRow() {
    const container = document.querySelector<HTMLElement>('.report_boxs')
    const item = document.querySelector<HTMLElement>('.report_box_item')
    if (!container || !item) return 0
    const container_width = container.offsetWidth
    const box_width = item.offsetWidth
    return Math.round(container_width / box_width)
  }

  function setLastNum(num: number) {
    lastNum.value = num
  }

  function setLastRow(num: number) {
    lastRow.value = num
  }

  function setList(newList: AdvPackage<AdvBannerBox>[]) {
    list.value = newList
  }

  function getPackagesIds(packages: AdvPackage<AdvBannerBox>[]) {
    if (!packages?.length) return []
    const ids: (number | null)[] = []
    for (const itemPackage of packages) {
      ids.push(itemPackage.id)
    }
    return ids
  }

  function getPackageIdByBannerId(id: number) {
    for (const itemPackage of list.value) {
      for (const itemBanner of itemPackage.banners) {
        if (itemBanner.id === id) return itemPackage.id
      }
    }
    return null
  }

  function addImpression(id: number) {
    if (!list.value.length) return false
    const pack = list.value.find((p) =>
      p.banners?.find((b) => b.id === id)
    )
    if (!pack) return false
    pack.sys_impression++
    const itemBanner = pack.banners.find((b) => b.id === id)
    if (!itemBanner) return false
    itemBanner.sys_impression++
  }

  function view(id: number) {
    const action: AdvAction = {
      id,
      type: 'cpm_box',
      section: getSection(),
      action: 'view',
    }
    useAdvStat().value.push(action)

    addImpression(id)
    addViewedBanner(id)
    addViewedPackage(getPackageIdByBannerId(id))
  }

  function click(id: number) {
    const action: AdvAction = {
      id,
      type: 'cpm_box',
      section: getSection(),
      action: 'click',
    }
    useAdvStat().value.push(action)
    
    useNuxtApp().$ga.clickBanner('box')
  }

  function getViewedPackages() {
    const val = useCookie(cookieNameViewedPackages, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value
    if (!val) return []
    const viewedStr = val + ''
    if (viewedStr.indexOf(',') < 0) return [Number(viewedStr)]
    return viewedStr.split(',').map((item) => Number(item))
  }

  function setViewedPackages(ids: number[]) {
    if (!ids?.length) return
    useCookie(cookieNameViewedPackages, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value = ids.join(',')
  }

  function getViewedPackagesBanners() {
    const val = useCookie(cookieNameViewedPackagesBanners, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value
    if (!val) return []
    const viewedStr = val + ''
    if (viewedStr.indexOf(',') < 0) return [Number(viewedStr)]
    return viewedStr.split(',').map((item) => Number(item))
  }

  function setViewedPackagesBanners(ids: number[]) {
    if (!ids?.length) return
    useCookie(cookieNameViewedPackagesBanners, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value = ids.join(',')
  }

  function removeViewedPackage(id: number, arr: number[]) {
    return arr.filter((item) => item !== id)
  }

  function addViewedPackage(id: number | null) {
    if (!id) return
    const num = Number(id)
    let viewed = getViewedPackages()
    viewed = removeViewedPackage(num, viewed)
    viewed.push(num)
    setViewedPackages(viewed)
  }

  function addViewedBanner(id: number) {
    const num = Number(id)
    let viewed = getViewedPackagesBanners()
    viewed = removeViewedPackage(num, viewed)
    viewed.push(num)
    setViewedPackagesBanners(viewed)
  }

  function sortPackageBannersByViews(banners: AdvBannerBox[]) {
    const viewed = getViewedPackagesBanners()
    if (!viewed.length) return banners
    return banners.sort((a, b) => viewed.indexOf(a.id) - viewed.indexOf(b.id))
  }

  function sortPackageByViews(packages: AdvPackage<AdvBannerBox>[]) {
    const viewed = getViewedPackages()
    if (!viewed.length) return packages
    return packages.sort((a, b) => viewed.indexOf(a.id!) - viewed.indexOf(b.id!))
  }

  function flattenBanners(packages: AdvPackage<AdvBannerBox>[]) {
    if (!packages.length) return []
    const copy = JSON.parse(JSON.stringify(packages)) as AdvPackage<AdvBannerBox>[]
    const maxCount = copy.sort((a, b) => b.banners.length - a.banners.length)[0].banners.length
    for (const p of packages) {
      if (p.banners.length < maxCount) {
        for (let i = p.banners.length; i < maxCount; i++) {
          p.banners = [...p.banners, ...p.banners]
        }
        p.banners = p.banners.slice(0, maxCount)
      }
    }
    const result: AdvBannerBox[] = []
    for (let i = 0; i < maxCount; i++) {
      for (const p of packages) {
        if (p.banners[i]) result.push(p.banners[i])
      }
    }
    return result
  }

  function sortAndFilter() {

    if(!list.value?.length)
      return [];

    const packages = JSON.parse(JSON.stringify(list.value)) as AdvPackage<AdvBannerBox>[]
    let banners: AdvBannerBox[] = []
    if (getSection() === 'index') {
      let boostedPackages = packages.filter((p) => p.boosted)
      if (boostedPackages.length) {
        boostedPackages.sort((a, b) => a.sys_impression - b.sys_impression)
        boostedPackages = sortPackageByViews(boostedPackages)
        const boostedPackage = boostedPackages[0]
        let boosted_banner_2 = null
        if (boostedPackages.length && boostedPackage.banners.length) {
          let bpack0 = boostedPackages[0].banners
          if (bpack0.length > 1) {
            bpack0 = bpack0.sort((a, b) => a.sys_impression - b.sys_impression)
            bpack0 = sortPackageBannersByViews(bpack0)
            banners.push(bpack0[0])
          }
          if (boostedPackages.length && boostedPackage.banners.length > 1) {
            boosted_banner_2 = boostedPackage.banners
              .sort((a, b) => a.sys_impression - b.sys_impression)[1]
          } else {
            const boosted_banner_1 = boostedPackage.banners
              .sort((a, b) => a.sys_impression - b.sys_impression)[0]
            if (boosted_banner_1) banners.push(boosted_banner_1)
          }
        }
        const otherPackages = JSON.parse(JSON.stringify(packages)) as AdvPackage<AdvBannerBox>[]
        otherPackages.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
        const sortedOthers = sortPackageByViews(otherPackages)
        const boostedBanners = flattenBanners(boostedPackages)
        const otherBanners = flattenBanners(sortedOthers)
        banners = [...banners, ...otherBanners]
        if (boosted_banner_2) banners.push(boosted_banner_2)
        banners = [...banners, ...boostedBanners]
      } else {
        packages.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
        const sortedAll = sortPackageByViews(packages)
        banners = flattenBanners(sortedAll)
      }
    } else {
      packages.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
      const sorted = sortPackageByViews(packages)
      banners = flattenBanners(sorted)
    }
    prepared.value = banners
    return banners
  }

  function prepare() {
    setLastNum(0)
    setLastRow(0)
    sortAndFilter()
  }

  function getNextBox() {
    if (lastNum.value > prepared.value.length - 1) {
      lastNum.value = 0
    }
    const ret = prepared.value[lastNum.value]
    lastNum.value++
    insertCount.value++
    return ret
  }

  function apply() {
    if (useDisabledAdv().value) return null
    if(useAdvNo().value) return;
    if (isIgnoreSection()) return false
    if (isIgnorePages()) return false
    if (getSection() === 'index') applyFeed()
    else applyList()
  }

  function applyList() {
    if (useDisabledAdv().value) return null
    if (!isReadyApply()) return false

    let count_in_row = countInRow()
    let count_rep = document.querySelectorAll('.report_boxs .report_box_item').length
    let count_box = document.querySelectorAll('.report_boxs .cpm_box').length
    if (!count_rep || !count_in_row) return false
    count_rep += count_box
    const count_row = Math.ceil(count_rep / count_in_row)
    const start_row = 1
    const repeat_row = isMobile() ? 4 : 3
    for (let i = 0; i < count_row / (repeat_row - 1); i++) {
      if (count_row >= lastRow.value + repeat_row) {
        const rowNow = lastRow.value + (lastRow.value ? repeat_row : start_row)
        const num_start = rowNow * count_in_row - count_box - 1
        let num_rosition =
          num_start + (count_in_row > 2 ? getRandomInt(Math.round(count_in_row / 2), count_in_row) - 1 : 1)
        const items = document.querySelectorAll('.report_boxs .report_box_item')
        if (items[num_rosition]) {
          const data_box = getNextBox()
          const mountNode = document.createElement('div')
          mountNode.className = 'cpm_box report_format'
          const app = createApp(AdvBoxComponent, {
            data: data_box, 
          })
          // app.use(VueLazyLoad)
          app.mount(mountNode)
          items[num_rosition].after(mountNode)
          lastRow.value = rowNow
          count_box++
        }
      }
    }
  }

  function applyFeed() {
    if (useDisabledAdv().value) return null
    if (!isReadyApply()) return false
    const count_in_row = countInRow()
    const count_row = document.querySelectorAll('.report_boxs').length
    if (!count_row || !count_in_row) return false
    const start_row = 0
    const repeat_row = isMobile() ? 4 : 3
    if (!lastRow.value) lastRow.value = 0
    for (let i = 0; i < count_row / (repeat_row - 1); i++) {
      if (count_row >= lastRow.value) {
        const rowNow = lastRow.value
        let num_rosition = 0
        if (!rowNow) {
          const num_start = count_in_row > 2 ? count_in_row - 1 : 0
          num_rosition =
            num_start + (count_in_row > 2 ? getRandomInt(Math.round(count_in_row / 2), count_in_row - 2) - 1 : 0)
        } else {
          num_rosition = count_in_row > 2 ? getRandomInt(Math.round(count_in_row / 2), count_in_row - 2) - 1 : 0
        }
        const container = document.querySelectorAll('.report_boxs')[rowNow]
        if (container) {
          const items = container.querySelectorAll('.report_box_item')
          if (items[num_rosition]) {
            const data_box = getNextBox()
            const mountNode = document.createElement('div')
            mountNode.className = 'cpm_box report_format'
            const app = createApp(AdvBoxComponent, {
              data: data_box,
            })
            app.mount(mountNode)
            const node = items[num_rosition]
            node.after(mountNode)
            lastRow.value = lastRow.value ? rowNow + repeat_row : 3
          }
        }
      }
    }
  }

  return {
    list,
    prepared,
    lastRow,
    lastNum,
    insertCount,
    elements,
    cookieNameViewedPackages,
    cookieNameViewedPackagesBanners,
    getRandomInt,
    isMobile,
    getSection,
    isIgnorePages,
    isIgnoreSection,
    clear,
    isReadyApply,
    countInRow,
    setLastNum,
    setLastRow,
    setList,
    getPackagesIds,
    getPackageIdByBannerId,
    addImpression,
    view,
    click,
    getViewedPackages,
    setViewedPackages,
    getViewedPackagesBanners,
    setViewedPackagesBanners,
    removeViewedPackage,
    addViewedPackage,
    addViewedBanner,
    sortPackageBannersByViews,
    sortPackageByViews,
    prepare,
    flattenBanners,
    sortAndFilter,
    getNextBox,
    apply,
    applyList,
    applyFeed,
    setIgnore,
  }
}