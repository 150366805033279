const HANDLER_KEY = '__dbltap_handler__'
const DOUBLE_TAP_THRESHOLD = 300 

function bind(el: HTMLElement, binding: any) {

  unbind(el)

  const callback = binding.value
  if (typeof callback !== 'function') {
    console.warn(
      `[v-${binding.name}] ожидает функцию, но получено: ${typeof callback}`
    )
    return
  }

  let lastTap = 0

  el[HANDLER_KEY] = (event: TouchEvent | MouseEvent) => {
    const currentTime = Date.now()
    const tapInterval = currentTime - lastTap
    
    if (tapInterval < DOUBLE_TAP_THRESHOLD && tapInterval > 0) {
      callback(event)
    }
    lastTap = currentTime
    if (event.cancelable) {
      event.preventDefault()
    }
  }

  el.addEventListener('touchend', el[HANDLER_KEY] as EventListener, false)
  el.addEventListener('click', el[HANDLER_KEY] as EventListener, false)
}

function unbind(el: HTMLElement) {
  if (el[HANDLER_KEY]) {
    el.removeEventListener('touchend', el[HANDLER_KEY], false)
    el.removeEventListener('click', el[HANDLER_KEY], false)
    delete el[HANDLER_KEY]
  }
}

export default {
  created(el: HTMLElement, binding: any) {
    bind(el, binding)
  },
  updated(el: HTMLElement, binding: any) {
    if (binding.value === binding.oldValue) return
    bind(el, binding)
  },
  beforeUnmount(el: HTMLElement) {
    unbind(el)
  }
}