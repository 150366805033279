// composables/useClient.ts

import { useRequestHeaders } from '#app'; // Nuxt 3 хук

export type ClientType = 'bot' | 'user' | 'ai-scanner' | 'pagespeed';

export function detectClientType() {
  let userAgent = '';

  if (import.meta.server) {
    const headers = useRequestHeaders(['user-agent']);
    userAgent = headers['user-agent'] || '';
  } 
  else if (import.meta.client) {
    userAgent = navigator.userAgent;
  }

  const ua = userAgent.toLowerCase();

  let clientType: ClientType = 'user';
  if (/lighthouse|pagespeed|speed insights/i.test(ua)) {
    clientType = 'pagespeed';
  } else if (/chatgpt|gpt|openai|bard|anthropic|claude/i.test(ua)) {
    clientType = 'ai-scanner';
  } else if (/googlebot|bingbot|yandex|baiduspider|bot|crawler|spider|crawling|facebookexternalhit/i.test(ua)) {
    clientType = 'bot';
  }

  const isMobile = /mobile|android|touch|webos|hpwos|ipad|tablet|kindle|playbook|silk/i.test(ua);

  return {
    clientType,
    isMobile,
  };
}


export function useClient() {

  const state = useState('clientInfo', () => {
    const { clientType, isMobile } = detectClientType();
    return {
      clientType,
      isMobile,
    };
  });


  return {
    clientType: computed(() => state.value.clientType),
    isMobile: computed(() => state.value.isMobile),
  };
}


export const useClientIsBot = () => {
  const { clientType } = useClient();
  return computed(() => clientType.value === 'bot' || clientType.value === 'ai-scanner' || clientType.value === 'pagespeed');
};

export const useClientIsMobile = () => {
  const { isMobile } = useClient();
  return computed(() => isMobile.value);
};