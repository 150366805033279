import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import _00_auth_UmuIJnuh_CnDh3ir9p5aonx6apoQjt6eFMPh3OzGZWU from "/app/plugins/00.auth.ts";
import _00_error_jqlI8fglA1ecxUzEWf_NyXwnPbIFePp_1k7vTPeEOiQ from "/app/plugins/00.error.ts";
import _01_api_solxhbql_nGtWsvPaEH3jE9_vCAVWqawqFNRFW8WM30 from "/app/plugins/01.api.ts";
import _08_gtag_conditional_client_YhTz3C3g016UzLboScvm_4VnfZ8SZ_89Wi3URBrvfc0 from "/app/plugins/08.gtag-conditional.client.ts";
import _08_gtag_uG36Gl_sFBJPFMpEkZNKHi4cKKStOo9H1CQBNCHcWTw from "/app/plugins/08.gtag.ts";
import _09_adv_LCXGyEHSG9pAwdEp0Qlpd5jfQo__zEIS_xkKB8sgYK0 from "/app/plugins/09.adv.ts";
import _10_progress_bar_content_yo9piR2M6twG5XnYabqB68Nn48LdgF1h8H6OXYXA9Z0 from "/app/plugins/10.progress-bar-content.ts";
import bfcache__g2qmnMI45kVVfQWu4Zm2mYVAwP4sgkcGzr7XPjE8HQ from "/app/plugins/bfcache.ts";
import complains_69zT9JBit7O11t_8Lcn5mRGfXnUFO7HMxgOcgZBWWDI from "/app/plugins/complains.ts";
import constants_J3cnttORpMlLP23Hf7Bz9a6_WGIiAOMSRoUxYat93hQ from "/app/plugins/constants.ts";
import convert_IMBbRaEQCKkLEN2bfNfLMBIOpGAN7uaNXNtFz2KsSYk from "/app/plugins/convert.ts";
import dayjs_OfOwibPyVolRS8B93_Pyn8_ay3EIa7gwAUSl8k3uQ68 from "/app/plugins/dayjs.ts";
import debug_8sCE3k1GcQb4cz54aP8Px9Aupj7yM9rUcGsaeMzwytw from "/app/plugins/debug.ts";
import directive_dbltap_xYZCM7k37oqpW0B8r4_1dhEPj3J405V7RBSRTmOtmR8 from "/app/plugins/directive-dbltap.ts";
import disable_zoom_bIuZg8GXkaxLJSxmph_B5_r0qU_pgdVGVWLYkkkASiw from "/app/plugins/disable-zoom.ts";
import donut_O1ZGlmbk_ZLr7p6khvguHMdLZrodFc4lPWNAWwhid_M from "/app/plugins/donut.ts";
import follow_khZpJ26rda2IDpVHcbO_A8P_6gOcH1PB45S2EOXgIQY from "/app/plugins/follow.ts";
import google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs from "/app/plugins/google-recaptcha.ts";
import head_opHIlrBVS2nmzhHQVu_gE9PDRtfIkUzHLkjoKzDz_LI from "/app/plugins/head.ts";
import helper_VKMOqczrHwTkzFlre1ymht5_MrpDyLjN2PtrkKDwzLM from "/app/plugins/helper.ts";
import i18n_cloud_Rq8qNGn2V1fUttpZUhMv0_CV9DLA7XTvtjoNOOhtIhU from "/app/plugins/i18n-cloud.ts";
import i18n_static_jxzISXTUaTWYMUOep9NqvkttX_fyfLSHlU_ENmICelM from "/app/plugins/i18n-static.ts";
import likes_C_FB84NTdm7UFFrNxum_d5yz6Wsb0MPdu0Nr3Yhs_V8 from "/app/plugins/likes.ts";
import log_js_error_lbTGmUW4ryXKLSUtkGFOSFH78TNg7zCcOr2dZXu8MlE from "/app/plugins/log-js-error.ts";
import masonry_wall_HicsmeBlyJs3nFWnCcs1obMepbU_7qWC_jHuZqnOGTQ from "/app/plugins/masonry-wall.ts";
import observer_KXEHTAAjU68l7XJzGgY7kndfUtp9yliRVSc0lFDx6SA from "/app/plugins/observer.ts";
import patcher_GnGpJ33aREIOgPXNJNUWrUdEeCYxcf5rxvS4hHjS7BM from "/app/plugins/patcher.ts";
import plurals__s6PXah4FaXiYehkjTnLE8iyQjtm0jMUrQQHsSxEYdM from "/app/plugins/plurals.ts";
import popup_fuxydDEc4PLCBfjW0Btj1BHWxBvfofYVWSVkqjirCb8 from "/app/plugins/popup.ts";
import presave_0YxcKmvxRP_ZzhvKNN6urMRN0X_IuVh9GlwUjTS6iAU from "/app/plugins/presave.ts";
import rand_m9hwqHrRcaZU0ZIxPICnEUfqz7xoIVDb5OsgHOfaGYI from "/app/plugins/rand.ts";
import scroll_prevent_Mm5Kb8cUUFAMTxx53zeKV5jh3CYyGVxQSdmhWqpf4lM from "/app/plugins/scroll-prevent.ts";
import scroll_to_B0m5EJK_1eWNsY0DcMQXP0p5UaFx7roI4OMJAfd_Ne4 from "/app/plugins/scroll-to.ts";
import security_DalZ49LwMt9NcaXfoKe_1jBZ7_EJCgZLgs5URyZ6XU4 from "/app/plugins/security.ts";
import server_secret_hVLLlemrFwYHQJxrpoNnmChZPsQKi2HqKmad7ki3EB4 from "/app/plugins/server-secret.ts";
import spec_chars_r5e2_ZTWDBBQhH72j3lBwsuvnwKaTxdAu0Sej_jtPKM from "/app/plugins/spec-chars.ts";
import translate_kx1RITRbLuh5MqM93tr63__eymOO52RdxSGoRc31kE4 from "/app/plugins/translate.ts";
import uniq_DjYhhAf7vVVgBnlZN9T3tixdhlmeCOYYq3wHpMPTPmY from "/app/plugins/uniq.ts";
import user_updates_EtEwKlOnZvZbqsyWzZOs8SCNNQyq4aFh1srb4Jnn5_o from "/app/plugins/user-updates.ts";
import vartpl_qEHSLAHC_nbxrHpQVhTA2Hd7clgHBs4K1dxob21vAzE from "/app/plugins/vartpl.ts";
import vue3_touch_events_hbXlWPkAi6y_lxgE4ESFlxEdzWckaSTYk_O_Ft5xiuY from "/app/plugins/vue3-touch-events.ts";
import vuedraggable_r_kgVGt_l_xTvLWXM_UV8ake9sWdTEgNHgJNQU963Hk from "/app/plugins/vuedraggable.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  _00_auth_UmuIJnuh_CnDh3ir9p5aonx6apoQjt6eFMPh3OzGZWU,
  _00_error_jqlI8fglA1ecxUzEWf_NyXwnPbIFePp_1k7vTPeEOiQ,
  _01_api_solxhbql_nGtWsvPaEH3jE9_vCAVWqawqFNRFW8WM30,
  _08_gtag_conditional_client_YhTz3C3g016UzLboScvm_4VnfZ8SZ_89Wi3URBrvfc0,
  _08_gtag_uG36Gl_sFBJPFMpEkZNKHi4cKKStOo9H1CQBNCHcWTw,
  _09_adv_LCXGyEHSG9pAwdEp0Qlpd5jfQo__zEIS_xkKB8sgYK0,
  _10_progress_bar_content_yo9piR2M6twG5XnYabqB68Nn48LdgF1h8H6OXYXA9Z0,
  bfcache__g2qmnMI45kVVfQWu4Zm2mYVAwP4sgkcGzr7XPjE8HQ,
  complains_69zT9JBit7O11t_8Lcn5mRGfXnUFO7HMxgOcgZBWWDI,
  constants_J3cnttORpMlLP23Hf7Bz9a6_WGIiAOMSRoUxYat93hQ,
  convert_IMBbRaEQCKkLEN2bfNfLMBIOpGAN7uaNXNtFz2KsSYk,
  dayjs_OfOwibPyVolRS8B93_Pyn8_ay3EIa7gwAUSl8k3uQ68,
  debug_8sCE3k1GcQb4cz54aP8Px9Aupj7yM9rUcGsaeMzwytw,
  directive_dbltap_xYZCM7k37oqpW0B8r4_1dhEPj3J405V7RBSRTmOtmR8,
  disable_zoom_bIuZg8GXkaxLJSxmph_B5_r0qU_pgdVGVWLYkkkASiw,
  donut_O1ZGlmbk_ZLr7p6khvguHMdLZrodFc4lPWNAWwhid_M,
  follow_khZpJ26rda2IDpVHcbO_A8P_6gOcH1PB45S2EOXgIQY,
  google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs,
  head_opHIlrBVS2nmzhHQVu_gE9PDRtfIkUzHLkjoKzDz_LI,
  helper_VKMOqczrHwTkzFlre1ymht5_MrpDyLjN2PtrkKDwzLM,
  i18n_cloud_Rq8qNGn2V1fUttpZUhMv0_CV9DLA7XTvtjoNOOhtIhU,
  i18n_static_jxzISXTUaTWYMUOep9NqvkttX_fyfLSHlU_ENmICelM,
  likes_C_FB84NTdm7UFFrNxum_d5yz6Wsb0MPdu0Nr3Yhs_V8,
  log_js_error_lbTGmUW4ryXKLSUtkGFOSFH78TNg7zCcOr2dZXu8MlE,
  masonry_wall_HicsmeBlyJs3nFWnCcs1obMepbU_7qWC_jHuZqnOGTQ,
  observer_KXEHTAAjU68l7XJzGgY7kndfUtp9yliRVSc0lFDx6SA,
  patcher_GnGpJ33aREIOgPXNJNUWrUdEeCYxcf5rxvS4hHjS7BM,
  plurals__s6PXah4FaXiYehkjTnLE8iyQjtm0jMUrQQHsSxEYdM,
  popup_fuxydDEc4PLCBfjW0Btj1BHWxBvfofYVWSVkqjirCb8,
  presave_0YxcKmvxRP_ZzhvKNN6urMRN0X_IuVh9GlwUjTS6iAU,
  rand_m9hwqHrRcaZU0ZIxPICnEUfqz7xoIVDb5OsgHOfaGYI,
  scroll_prevent_Mm5Kb8cUUFAMTxx53zeKV5jh3CYyGVxQSdmhWqpf4lM,
  scroll_to_B0m5EJK_1eWNsY0DcMQXP0p5UaFx7roI4OMJAfd_Ne4,
  security_DalZ49LwMt9NcaXfoKe_1jBZ7_EJCgZLgs5URyZ6XU4,
  server_secret_hVLLlemrFwYHQJxrpoNnmChZPsQKi2HqKmad7ki3EB4,
  spec_chars_r5e2_ZTWDBBQhH72j3lBwsuvnwKaTxdAu0Sej_jtPKM,
  translate_kx1RITRbLuh5MqM93tr63__eymOO52RdxSGoRc31kE4,
  uniq_DjYhhAf7vVVgBnlZN9T3tixdhlmeCOYYq3wHpMPTPmY,
  user_updates_EtEwKlOnZvZbqsyWzZOs8SCNNQyq4aFh1srb4Jnn5_o,
  vartpl_qEHSLAHC_nbxrHpQVhTA2Hd7clgHBs4K1dxob21vAzE,
  vue3_touch_events_hbXlWPkAi6y_lxgE4ESFlxEdzWckaSTYk_O_Ft5xiuY,
  vuedraggable_r_kgVGt_l_xTvLWXM_UV8ake9sWdTEgNHgJNQU963Hk,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]