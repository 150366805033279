
export function useRouteHistory() {
 

  function isDifferent(link: string) {
    const currentUrl = new URL(window.location.href)
    const linkUrl = new URL(link, window.location.href)

    return (
      currentUrl.pathname !== linkUrl.pathname ||
      currentUrl.search !== linkUrl.search ||
      currentUrl.hash !== linkUrl.hash
    )
  }

  function getCurrent() {
    return window.location.href + window.location.hash;
  }

  function push(link: string) {
    if(!isDifferent(link)) return;
    window.history.pushState(JSON.parse(JSON.stringify(window.history.state || {})), '', link);
  }
 
  function replace(link: string) {
    if(!isDifferent(link)) return;
    window.history.replaceState(JSON.parse(JSON.stringify(window.history.state || {})), '', link);
  }

  return {
    push,
    replace,
  }
}