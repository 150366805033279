

export default defineNuxtPlugin(nuxtApp => {  


  var convert = {
      
    legitimed: function(unit: string, current: string, vl: number, dec?: number): number | null {
      dec = dec ? dec : 2;
      if(vl == null) return null;
      if(unit == 'temperature')
        if(current == 'f') return vl;
        else return this.c_f(vl,0);                         
      else if(unit == 'square')
        if(current == 'metric') return vl;
        else return this.inch_cm(vl,dec);
      else if(unit == 'length')
        if(current == 'metric') return vl;
        else return this.inch_cm(vl,dec);
      else if(unit == 'height')
        if(current == 'metric') return vl;
        else return this.inch_cm(vl,dec);
      else if(unit == 'weight')
        if(current == 'metric') return vl;
        else return this.oz_gr(vl,dec);
      else if(unit == 'volume')
        if(current == 'l') return vl;
        else return this.gal_l(vl,dec);
      else if(unit == 'tds')
        if(current == 'ppm') return vl;
        else return this.ec_ppm(vl,dec);
      else if(unit == 'nutrient')
        if(current == 'mll') return vl;
        else if(current == 'tspgal') return this.tspgal_mll(vl,2);        
        else return this.mlgal_mll(vl,dec);
      else return vl;
    },  
    universal: function(unit: string, target: string, vl: number): number | null {     
      if(vl == null) return null;                
      if(unit == 'temperature')
        if(target == 'f') return vl;
        else return this.f_c(vl,0);                         
      else if(unit == 'square')
        if(target == 'metric') return vl;
        else return this.cm_inch(vl,2);
      else if(unit == 'length')
        if(target == 'metric') return vl;
        else return this.cm_inch(vl,2);
      else if(unit == 'height')
        if(target == 'metric') return vl;
        else return this.cm_inch(vl,2);
      else if(unit == 'weight')
        if(target == 'metric') return vl;
        else return this.gr_oz(vl,2);
      else if(unit == 'volume')
        if(target == 'l') return vl;
        else return this.l_gal(vl,2);
      else if(unit == 'tds')
        if(target == 'ppm') return this.dec(vl,2);
        else return this.ppm_ec(vl,2);
      else if(unit == 'nutrient')
        if(target == 'mll') return vl;
        else if(target == 'tspgal') return this.mll_tspgal(vl,2);        
        else return this.mll_mlgal(vl,2);
      else return vl;
    },
    rn: function(vl:number, dec?: number){

    },
    round: function(unit: string, measure: string, vl: number){
      if(unit == 'temperature') 
        if(measure == 'c') return this.dec(vl);
        else return this.dec(vl);
      else if(unit == 'square')
        if(measure == 'metric') return this.dec(vl);
        else return this.dec(vl,2);
      else if(unit == 'length')
        if(measure == 'metric') return this.dec(vl);
        else return this.dec(vl,2);
      else if(unit == 'height')
        if(measure == 'metric') return this.dec(vl);
        else return this.dec(vl, 2);
      else if(unit == 'weight')
        if(measure == 'metric') return this.dec(vl);
        else return this.dec(vl, 2);
      else if(unit == 'volume')
        if(measure == 'l') return this.dec(vl);
        else return this.dec(vl, 2);
      else if(unit == 'tds')
        if(measure == 'ppm') return this.dec(vl,2);
        else return this.dec(vl,2);
      else if(unit == 'nutrient')
        if(measure == 'mll') return this.dec(vl);
        else if(measure == 'tspgal') return this.dec(vl,2);   
        else return this.dec(vl,2);
      else return this.dec(vl);
    },      
    view: function(unit: string, target: string, vl: number, dec? : number){     
      dec = dec ?? 2;
      if(vl == null) return null;                
      if(unit == 'temperature')
        if(target == 'f') return this.dec(vl,dec);
        else return this.f_c(vl,0);
      else if(unit == 'square')
        if(target == 'metric') return this.dec(vl,dec);
        else return this.cm_inch(vl,dec);
      else if(unit == 'length')
        if(target == 'metric') return this.dec(vl,dec);
        else return this.cm_inch(vl,dec);
      else if(unit == 'height')
        if(target == 'metric') return this.dec(vl,dec);
        else return this.cm_inch(vl,dec);
      else if(unit == 'weight')
        if(target == 'metric') return this.dec(vl,dec);
        else return this.gr_oz(vl,dec);
      else if(unit == 'volume')
        if(target == 'l') return this.dec(vl,dec);
        else return this.l_gal(vl,dec);
      else if(unit == 'tds')
        if(target == 'ppm') return vl;
        else return this.ppm_ec(vl,dec);
      else if(unit == 'nutrient')
        if(target == 'mll') return this.dec(vl,dec);
        else if(target == 'tspgal') return this.mll_tspgal(vl,dec);        
        else return this.mll_mlgal(vl,dec);
      else return this.dec(vl,dec);
    },
    cnv: function(mn: number, vl: number, dec? : number){              
      return this.dec( vl * mn, dec );
    },  
    dec: function(vl: number, dec? : number){            
      let decvl = Math.pow(10, dec ?? 10);
      return Math.round(vl * decvl) / decvl;      
    },  
    // height
    cm_inch: function(vl: number, dec? : number){              
      return this.cnv(0.393701, vl, dec);
    },  
    inch_cm: function(vl: number, dec? : number){              
      return this.cnv(2.54, vl, dec);
    },  
    // weight
    gr_oz: function(vl: number, dec? : number){              
      return this.cnv(0.035274, vl, vl < 0.0099?3:2);
    },  
    oz_gr: function(vl: number, dec? : number){              
      return this.cnv(28.3495, vl, dec);
    },  
    // length
    cm_ft: function(vl: number, dec? : number){              
      return this.cnv(0.0328084, vl, dec);
    },  
    ft_cm: function(vl: number, dec? : number){              
      return this.cnv(30.48, vl, dec);
    },  
    // length 2
    m_ft: function(vl: number, dec? : number){              
      return this.cnv(3.28084, vl, dec);
    },  
    ft_m: function(vl: number, dec? : number){              
      return this.cnv(0.3048, vl, dec);
    },  
    // place
    m2_ft2: function(vl: number, dec? : number){              
      return this.cnv(10.7639, vl, dec);
    },  
    ft2_m2: function(vl: number, dec? : number){              
      return this.cnv(0.092903, vl, dec);
    },  
    // temp
    f_c: function(vl: number, dec? : number){              
      return this.dec( (5/9)*(vl-32), dec );
    },  
    c_f: function(vl: number, dec? : number){              
      return this.dec( (9/5)*vl+32, dec );
    },  
    // tds
    ppm_ec: function(vl: number, dec? : number){              
      return this.dec( (vl*2)/1000, dec );
    },  
    ec_ppm: function(vl: number, dec? : number){              
      return this.dec( (vl*1000)/2, dec );      
    },  
    // 
    l_gal: function(vl: number, dec? : number){              
      return this.cnv(0.264172, vl, dec);
    },  
    gal_l: function(vl: number, dec? : number){              
      return this.cnv(3.78541, vl, dec);
    },  
    // nutrients
    mll_mlgal: function(vl: number, dec? : number){              
      return this.cnv(3.78541, vl, dec);
    },  
    mll_tspgal: function(vl: number, dec? : number){              
      return this.cnv(0.768, vl, dec);
    },  
    mlgal_mll: function(vl: number, dec? : number){              
      return this.cnv(0.264172, vl, dec);
    },  
    tspgal_mll: function(vl: number, dec? : number){
      return this.cnv(1.30208, vl, dec);
    },  
    
  }
  
 
  return {
    provide: {
      convert: convert,
    },
  };

})