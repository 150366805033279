
export default defineNuxtRouteMiddleware((to, from) => {
 
  const { gtag } = useGtag()

  gtag('event', 'page_view', {
    page_title: document?.title,
    page_location: window?.location.href,
    page_path: to.fullPath,
  }) 
 
})