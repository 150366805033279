import { defu } from 'defu'
import { appendResponseHeader, H3Event } from 'h3'
import type { FetchError, FetchOptions } from 'ohmyfetch' // опционально для типизации

/**
 * Тип опций, которые мы будем принимать в useCustomFetch.
 * Вы можете расширить или упростить их, на ваше усмотрение.
 */
export interface CustomFetchOptions extends Omit<FetchOptions<'json'>, 'onResponse' | 'onRequest' | 'onResponseError'> {
  domain?: 'UPLOAD' | string;
  onResponse?(ctx: { response: Response; data: any }): void;
  onResponseError?(ctx: { response?: Response; error?: any }): void;
}

/**
 * Адаптированный useCustomFetch, который вместо useFetch использует $fetch.
 */
export async function useCustomFetch<T> (
  url: string,
  options: CustomFetchOptions = {}
): Promise<T> {
  const config = useRuntimeConfig()

  // Собираем куки в единую строку
  const getCookieString = (): string => {
    const authGuest = useCookie('auth:guest', { maxAge: 60 * 60 * 24 * 31 * 6 })
    const unitVolume = useCookie('unit_volume')
    const unitTemperature = useCookie('unit_temperature')
    const unitWeight = useCookie('unit_weight')
    const unitTds = useCookie('unit_tds')
    const unitLength = useCookie('unit_length')
    const details = useCookie('details')
    const cookieUseExperimentsRegion = useCookie('useExperimentsRegion')

    let cookieString = ''
    if (unitVolume.value) cookieString += `unit_volume=${unitVolume.value}; `
    if (unitTemperature.value) cookieString += `unit_temperature=${unitTemperature.value}; `
    if (unitWeight.value) cookieString += `unit_weight=${unitWeight.value}; `
    if (unitTds.value) cookieString += `unit_tds=${unitTds.value}; `
    if (unitLength.value) cookieString += `unit_length=${unitLength.value}; `
    if (authGuest.value) cookieString += `auth:guest=${authGuest.value}; `
    if (details.value) cookieString += `details=${details.value}; `
    if (cookieUseExperimentsRegion.value) cookieString += `useExperimentsRegion=${cookieUseExperimentsRegion.value}; `

    return cookieString
  }

  // Хелпер чтобы получить заголовок из входящего запроса (актуально на сервере)
  const getHeader = (name: string) => {
    const headers = useRequestHeaders([name])
    return headers[name]
  }

  // Куки
  const userAuth = useCookie('auth:token', { maxAge: 60 * 60 * 24 * 31 * 6 })
  const guestAuth = useCookie('auth:guest', { maxAge: 60 * 60 * 24 * 31 * 6 })
  // Если есть в куках auth:api — берём оттуда, иначе useApiSecret()
  const apiCookie = useCookie('auth:api', { maxAge: 60 * 60 * 24 * 31 * 6 })
  const apiKey = apiCookie.value ? apiCookie : useApiSecret()

  // Нужны ли отладочные данные (пример)
  const debugFor = useDebugListFor()

  const configOrigin = ref('')
  const configApiSecret = ref('')
  const event = useRequestEvent() // доступен только на сервере

  if (import.meta.server) {
    configOrigin.value = config.origin ?? ''
    configApiSecret.value = config.apiSecret ?? ''
  }

  // Удаляем префикс /api, если есть
  url = url.replace(/^\/api/, '')

  // Формируем конечный url
  if (import.meta.server) {
    // Сервер — стучимся на baseAPILocal
    url = config.public.baseAPILocal + url
  } else {
    // Клиент
    if (options.domain === 'UPLOAD') {
      url = config.public.baseAPIUpload + url
    } else {
      url = config.public.baseAPI + url
    }
  }

  // Пример генерации уникальных заголовков на клиенте
  const { $uniq, hooks } = useNuxtApp()
  let uniq = ''
  let rniq = ''
  if (import.meta.client) {
    const tmpUniq = $uniq.code()
    uniq = tmpUniq.code
    rniq = tmpUniq.rnd
  }

  // Формируем заголовки
  const headers: Record<string, string> = {
    'Authorization': userAuth.value ? `Bearer ${userAuth.value}` : '',
    'X-Access': apiKey.value ?? '',
    'X-Guest': guestAuth.value ?? '',
    'X-Origin': configOrigin.value ?? '',
    'X-Pass': configApiSecret.value ?? '',
    'X-Uniq': uniq ?? '',
    'X-Rniq': rniq ?? '',
    'Cookie': getCookieString()
  }

  // На сервере передаём дополнительный заголовок CF-IPCountry, если есть
  if (import.meta.server) {
    headers['CF-IPCountry'] = getHeader('cf-ipcountry') || ''
  }

  // Подготавливаем опции для $fetch (используем defu для объединения)
  const defaults: FetchOptions<'json'> = {
    method: options.method || 'GET',
    credentials: 'include',
    headers
  }

  // Объединяем опции, переданные извне, с нашими "дефолтными"
  const fetchOptions = defu(options, defaults) as FetchOptions<'json'>

  // Удаляем из fetchOptions то, что нам не нужно пробрасывать напрямую (onResponse / onResponseError),
  // так как мы обработаем это вручную ниже.  
  // Или же можно оставить, если хотите использовать перехватчики $fetch (onRequest, onResponse и т.п.).
  delete (fetchOptions as any).onResponse
  delete (fetchOptions as any).onResponseError

  let data: T

  try {
    // Выполняем запрос
    const response: T = await $fetch<T>(url, fetchOptions)

    // Эмулируем onResponse:
    if (options.onResponse) {
      // Преобразуем объект, чтобы он хоть как-то напоминал контекст
      options.onResponse({
        response: ({} as Response), // в реальном сценарии вы можете подложить нужные данные
        data: response
      })
    }

    // Если у нас на сервере пришёл ответ с set-cookie, нужно прокинуть клиенту
    if (import.meta.server) {
      // Получение Set-Cookie из заголовков $fetch на сервере:
      // $fetch в Nuxt (ohmyfetch) не всегда напрямую возвращает их, возможно придётся
      // использовать перехватчики onResponse (fetchOptions.onResponse) или другой метод.
      //
      // Ниже — общий пример, но в реальном проекте может отличаться:
      // const setCookieHeader = response.headers?.get('set-cookie') ?? ''
      // if (setCookieHeader) {
      //   appendResponseHeader(event, 'set-cookie', setCookieHeader)
      // }

      // В вашем исходном коде был парсинг через split(','), если хотите сохранить логику — сделайте аналогично.
    }

    // Вызываем хуки, если нужно (как в вашем коде hooks.callHook)
    if (import.meta.client && !options.method) {
      hooks.callHook('custom:fetch:get', response).catch(() => {})
    }
    hooks.callHook('custom:fetch:any', response).catch(() => {})

    // Если нужно хранить отладочные данные
    if ((response as any)?.details) {
      debugFor.value.push(response) 
    }

    data = response
  } catch (error: any) {
    // Обработка ошибок
    // Эмулируем onResponseError
    if (options.onResponseError) {
      options.onResponseError({
        response: undefined, // либо подложить, если есть
        error
      })
    }

    // Пробрасываем ошибку дальше, либо обрабатываем как-то иначе
    throw error
  }

  return data
}