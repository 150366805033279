export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"},{"charset":"utf-8"},{"hid":"description","name":"description","content":""},{"hid":"google-site-verification","name":"google-site-verification","content":"9h8DLNNkUcxQXhf6pryQCSXOydgfEZTTJM8vVonUIsM"}],"link":[{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"manifest","href":"/favicon/site.webmanifest"},{"rel":"mask-icon","href":"/favicon/safari-pinned-tab.svg"},{"rel":"shortcut icon","href":"/favicon/favicon.ico"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"msapplication-config","content":"/favicon/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","title":"Growdiaries","viewport":"width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true