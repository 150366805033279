

export enum ProductCategoryType {
  AirConditioner = 'air_conditioner',
  AirFilter = 'air_filter',
  CO2Generator = 'co2_generator',
  Controller = 'controller',
  DripSystem = 'drip_system',
  GrowBox = 'grow_box',
  HydroponicSystem = 'hydroponic_system',
  Lamp = 'lamp',
  Medium = 'medium',
  Nutrient = 'nutrient',
  Seed = 'seed',
  Tent = 'tent',
  VentilationFan = 'ventilation_fan',
  Substrate = 'substrate',
}



export const ProductCategoryDescription: Record<ProductCategoryType, string> = {
  [ProductCategoryType.AirConditioner]: 'Air Conditioner – Devices that regulate temperature and humidity, maintaining optimal climate conditions for plant growth in controlled environments.',
  [ProductCategoryType.AirFilter]: 'Air Filter – Systems that purify the air by removing contaminants, ensuring a clean and healthy environment for plants and cultivation spaces.',
  [ProductCategoryType.CO2Generator]: 'CO2 Generator – Equipment that generates carbon dioxide to enhance photosynthesis, promoting healthier and faster plant growth in enclosed spaces.',
  [ProductCategoryType.Controller]: 'Controller – Centralized systems that manage and automate various aspects of the growing environment, including temperature, humidity, lighting, and ventilation.',
  [ProductCategoryType.DripSystem]: 'Drip System – Irrigation systems that deliver water and nutrients directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
  [ProductCategoryType.GrowBox]: 'Grow Box – Compact, all-in-one systems for indoor cultivation, featuring integrated lighting, ventilation, and often hydroponic setups to provide a self-contained environment for plant growth.',
  [ProductCategoryType.HydroponicSystem]: 'Hydroponic System – Soilless cultivation systems that deliver nutrients directly to plant roots through a water-based solution, enabling efficient and scalable plant growth.',
  [ProductCategoryType.Lamp]: 'Lamp – High-performance lighting solutions designed to provide the necessary light spectrum and intensity for photosynthesis, essential for indoor plant growth.',
  [ProductCategoryType.Medium]: 'Medium – Substrates used to support plant roots, providing structure and retaining moisture and nutrients in soilless or soil-based cultivation systems.',
  [ProductCategoryType.Nutrient]: 'Nutrient – Specialized formulations that supply essential minerals and compounds necessary for plant growth, ensuring healthy and vigorous development.',
  [ProductCategoryType.Seed]: 'Strains – High-quality plant seeds, the foundation of cultivation, chosen for their genetics and suitability to the growing environment.',
  [ProductCategoryType.Tent]: 'Tent – Portable, reflective enclosures that create a controlled growing environment by containing light, temperature, and humidity for optimal plant cultivation.',
  [ProductCategoryType.VentilationFan]: 'Ventilation Fan – Fans and ventilation systems that ensure proper air circulation, removing excess heat and humidity while supplying fresh air to maintain a healthy growing environment.',
  [ProductCategoryType.Substrate]: 'Substrate – Materials that serve as the foundation for plant roots, offering support, moisture retention, and nutrient availability for robust and healthy growth.',
};


export const ProductCategoryImage: Record<ProductCategoryType, string> = {
  [ProductCategoryType.AirConditioner]: '/images/product-categories/air_conditioner.svg',
  [ProductCategoryType.AirFilter]: '/images/product-categories/air_filter.svg',
  [ProductCategoryType.CO2Generator]: '/images/product-categories/co2_generator.svg',
  [ProductCategoryType.Substrate]: '/images/product-categories/substrate.svg',
  [ProductCategoryType.DripSystem]: '/images/product-categories/drip_system.svg',
  [ProductCategoryType.GrowBox]: '/images/product-categories/grow_box.svg',
  [ProductCategoryType.HydroponicSystem]: '/images/product-categories/hydroponic_system.svg',
  [ProductCategoryType.Lamp]: '/images/product-categories/lamp.svg',
  [ProductCategoryType.Medium]: '/images/product-categories/medium.svg',
  [ProductCategoryType.Nutrient]: '/images/product-categories/nutrient.svg',
  [ProductCategoryType.Seed]: '/images/product-categories/seed.svg',
  [ProductCategoryType.Tent]: '/images/product-categories/tent.svg',
  [ProductCategoryType.VentilationFan]: '/images/product-categories/ventilation_fan.svg',
  [ProductCategoryType.Controller]: '/images/product-categories/controller.svg',
};


export const ProductCategorySort = [
  ProductCategoryType.Seed,
  ProductCategoryType.Nutrient,
  ProductCategoryType.Lamp,
  ProductCategoryType.Tent,
  ProductCategoryType.GrowBox,
  ProductCategoryType.VentilationFan,
  ProductCategoryType.AirFilter,
  ProductCategoryType.AirConditioner,
  ProductCategoryType.Controller,
  ProductCategoryType.DripSystem,
  ProductCategoryType.HydroponicSystem,
  ProductCategoryType.CO2Generator,
  ProductCategoryType.Substrate,
]

 // typeGen:{
    //   "1":'Indica',
    //   "2":'Sativa',
    //   "3":'More indica',
    //   "4":'More sativa',
    //   "5":'Sativa/Indica',
    //   "6":'Sativa/Ruderalis',
    //   "7":'Indica/Ruderalis',
    //   "8":'Sativa/Indica/Ruderalis',
    // },


export enum ProductSeedGenType {
  Indica = 1,
  Sativa = 2,
  MoreIndica = 3,
  MoreSativa = 4,
  SativaIndica = 5,
  SativaRuderalis = 6,
  IndicaRuderalis = 7,
  SativaIndicaRuderalis = 8
}

export const ProductSeedGenTypeNames: Record<ProductSeedGenType, string> = {
  [ProductSeedGenType.Indica]: 'Indica',
  [ProductSeedGenType.Sativa]: 'Sativa',
  [ProductSeedGenType.MoreIndica]: 'More indica',
  [ProductSeedGenType.MoreSativa]: 'More sativa',
  [ProductSeedGenType.SativaIndica]: 'Sativa/Indica',
  [ProductSeedGenType.SativaRuderalis]: 'Sativa/Ruderalis',
  [ProductSeedGenType.IndicaRuderalis]: 'Indica/Ruderalis',
  [ProductSeedGenType.SativaIndicaRuderalis]: 'Sativa/Indica/Ruderalis',
};



export enum ProductSeedType {
  Fem = 1,
  Reg = 2,
  Auto = 3,
  Mix = 4,
  Clone = 5,
  Test = 6
}

export const ProductSeedTypeTrShort: Record<ProductSeedType, string> = {
  [ProductSeedType.Fem]: 'universal_type_seed_fem',
  [ProductSeedType.Reg]: 'universal_type_seed_reg',
  [ProductSeedType.Auto]: 'universal_type_seed_auto',
  [ProductSeedType.Mix]: 'universal_type_seed_mix',
  [ProductSeedType.Clone]: 'universal_type_seed_clone',
  [ProductSeedType.Test]: 'universal_category_test',
};

export const ProductSeedTypeIco: Record<ProductSeedType, string> = {
  [ProductSeedType.Fem]: '<span class="icn_par icon-fem"></span>',
  [ProductSeedType.Reg]: '<span class="icn_par icon-reg"></span>',
  [ProductSeedType.Auto]: '<span class="icn_par icon-auto"></span>',
  [ProductSeedType.Mix]: '<span class="icn_par icon-mix"></span>',
  [ProductSeedType.Clone]: '<span class="icn_par icon-mix"></span>',
  [ProductSeedType.Test]: '<span class="icn_par icon-mix"></span>',
};


export enum ProductTentType {
  Stealth = 1,
  Micro = 2,
  Regular = 3,
  Big = 4
}
 
export const ProductTentTypeTrShort: Record<ProductTentType, string> = {
  [ProductTentType.Stealth]: 'universal_type_tent_stealth',
  [ProductTentType.Micro]: 'universal_type_tent_micro',
  [ProductTentType.Regular]: 'universal_type_tent_regular',
  [ProductTentType.Big]: 'universal_type_tent_big',
};




// nutType:{
//   "0": '',
//   "1": 'nut_type_classic',
//   "2": 'nut_type_technical',
//   "3": 'nut_type_experimental',
// },
// nutStyle:{
//   "0": '',
//   "1": 'nut_style_solution_constant',
//   "2": 'nut_style_solution_one_time',
//   "3": 'nut_style_spray_on_leafs',
//   "4": 'nut_style_dissolve_in_substrate',
//   "5": 'nut_style_injection',
// },


export enum ProductNutrientType {
  Classic = 1,
  Technical = 2,
  Experimental = 3
}

export const ProductNutrientTypeTrShort: Record<ProductNutrientType, string> = {
  [ProductNutrientType.Classic]: 'nut_type_classic',
  [ProductNutrientType.Technical]: 'nut_type_technical',
  [ProductNutrientType.Experimental]: 'nut_type_experimental',
};


export enum ProductNutrientStyle {
  SolutionConstant = 1,
  SolutionOneTime = 2,
  SprayOnLeafs = 3,
  DissolveInSubstrate = 4,
  Injection = 5
}

export const ProductNutrientStyleTrShort: Record<ProductNutrientStyle, string> = {
  [ProductNutrientStyle.SolutionConstant]: 'nut_style_solution_constant',
  [ProductNutrientStyle.SolutionOneTime]: 'nut_style_solution_one_time',
  [ProductNutrientStyle.SprayOnLeafs]: 'nut_style_spray_on_leafs',
  [ProductNutrientStyle.DissolveInSubstrate]: 'nut_style_dissolve_in_substrate',
  [ProductNutrientStyle.Injection]: 'nut_style_injection',
};



export enum ProductNutrientState {
  Mll = 1,
  Gl = 2,
  Other = 3
}

export const ProductNutrientStateTrShort: Record<ProductNutrientState, string> = {
  [ProductNutrientState.Mll]: 'nut_state_mll',
  [ProductNutrientState.Gl]: 'nut_state_gl',
  [ProductNutrientState.Other]: 'nut_state_other',
};

export const ProductNutrientStateLabel: Record<ProductNutrientState, string> = {
  [ProductNutrientState.Mll]: 'nut_state_label_mll',
  [ProductNutrientState.Gl]: 'nut_state_label_gl',
  [ProductNutrientState.Other]: '',
};




export enum ProductLampType {
  Fl = 1,
  Hid = 2,
  Led = 3
}

export const ProductLampTypeImage: Record<ProductLampType, string> = {
  [ProductLampType.Fl]: '/images/product-categories/lamp_1.svg',
  [ProductLampType.Hid]: '/images/product-categories/lamp_2.svg',
  [ProductLampType.Led]: '/images/product-categories/lamp_3.svg'
};


export const ProductLampTypeTrShort: Record<ProductLampType, string> = {
  [ProductLampType.Fl]: 'universal_type_light_fl',
  [ProductLampType.Hid]: 'universal_type_light_hid',
  [ProductLampType.Led]: 'universal_type_light_led',
};

 
export enum ProductLampFaza {
  Vegetation = 0,
  Flowering = 1
}



export const ProductLampTypeInfo: Record<ProductLampType, { tr: string, img: string, desc: string }> = {
  [ProductLampType.Fl]: {
    tr: 'universal_type_light_fl',
    img: '/images/setup_diary/setup_diary_icons-06.svg',
    desc: 'Fluorescent – Energy-efficient lighting option that emits a soft, diffused light suitable for seedlings and clones.',
  },
  [ProductLampType.Hid]: {
    tr: 'universal_type_light_hid',
    img: '/images/setup_diary/setup_diary_icons-07.svg',
    desc: 'HID – High-intensity discharge lighting system that produces bright, intense light for optimal plant growth and flowering.',
  },
  [ProductLampType.Led]: {
    tr: 'universal_type_light_led',
    img: '/images/setup_diary/setup_diary_icons-08.svg',
    desc: 'LED – Light-emitting diode technology that provides a full spectrum of light for efficient and effective plant growth.',
  },
};


// Substrates
// Soil = 1,
// Perlite = 2,
// Vermiculite = 3,
// ExpandedClay = 4,
// CocoCoir = 5,
// MineralWool = 6,
// Other = 7,
export enum ProductSubstrateType {
  Soil = 1,
  Perlite = 2,
  Vermiculite = 3,
  ExpandedClay = 4,
  CocoCoir = 5,
  MineralWool = 6,
  Other = 7,
}

export const ProductSubstrateTypeImage: Record<ProductSubstrateType, string> = {
  [ProductSubstrateType.Soil]: '/images/product-categories/substrate_1.svg',
  [ProductSubstrateType.Perlite]: '/images/product-categories/substrate_2.svg',
  [ProductSubstrateType.Vermiculite]: '/images/product-categories/substrate_3.svg',
  [ProductSubstrateType.ExpandedClay]: '/images/product-categories/substrate_4.svg',
  [ProductSubstrateType.CocoCoir]: '/images/product-categories/substrate_5.svg',
  [ProductSubstrateType.MineralWool]: '/images/product-categories/substrate_6.svg',
  [ProductSubstrateType.Other]: '/images/product-categories/substrate_7.svg',
};

export const ProductSubstrateTypeTrShort: Record<ProductSubstrateType, string> = {
  [ProductSubstrateType.Soil]: 'universal_type_medium_soil',
  [ProductSubstrateType.Perlite]: 'universal_type_medium_perlite',
  [ProductSubstrateType.Vermiculite]: 'universal_type_medium_vermiculite',
  [ProductSubstrateType.ExpandedClay]: 'universal_type_medium_expanded_clay',
  [ProductSubstrateType.CocoCoir]: 'universal_type_medium_coco_coir',
  [ProductSubstrateType.MineralWool]: 'universal_type_medium_mineral_wool',
  [ProductSubstrateType.Other]: 'universal_type_medium_other',
};

export const ProductSubstrateTypeInfo: Record<ProductSubstrateType, { tr: string; img: string; desc: string }> = {
  [ProductSubstrateType.Soil]: {
    tr: 'universal_type_medium_soil',
    img: '/images/product-categories/substrate_1.svg',
    desc: 'Soil – Rich organic substrate ideal for natural cultivation and beneficial microorganisms.',
  },
  [ProductSubstrateType.Perlite]: {
    tr: 'universal_type_medium_perlite',
    img: '/images/product-categories/substrate_3.svg',
    desc: 'Perlite – Lightweight volcanic rock enhancing aeration and drainage in growing media.',
  }, 
  [ProductSubstrateType.Vermiculite]: {
    tr: 'universal_type_medium_vermiculite',
    img: '/images/product-categories/substrate_4.svg',
    desc: 'Vermiculite – Lightweight mineral substrate enhancing aeration and moisture retention.',
  },
  [ProductSubstrateType.ExpandedClay]: {
    tr: 'universal_type_medium_expanded_clay',
    img: '/images/product-categories/substrate_5.svg',
    desc: 'Expanded Clay – Lightweight, porous substrate providing excellent drainage and aeration.',
  },
  [ProductSubstrateType.CocoCoir]: {
    tr: 'universal_type_medium_coco_coir',
    img: '/images/product-categories/substrate_2.svg',
    desc: 'Coco Coir – Sustainable, airy substrate providing excellent drainage and oxygenation.',
  },
  [ProductSubstrateType.MineralWool]: {
    tr: 'universal_type_medium_mineral_wool',
    img: '/images/product-categories/substrate_6.svg',
    desc: 'Mineral Wool – Synthetic substrate providing excellent aeration and moisture retention.',
  },
  [ProductSubstrateType.Other]: {
    tr: 'universal_type_medium_other',
    img: '/images/product-categories/substrate_7.svg',
    desc: 'Other – Various substrates tailored for specific cultivation methods and plant needs.',
  },
};


// Substrates End


export const ProductCategoryTypeNameShort: Record<ProductCategoryType, string> = {
  [ProductCategoryType.Tent]: 'Tent',
  [ProductCategoryType.GrowBox]: 'Box',
  [ProductCategoryType.VentilationFan]: 'Fan',
  [ProductCategoryType.AirFilter]: 'Filt',
  [ProductCategoryType.AirConditioner]: 'Cond',
  [ProductCategoryType.DripSystem]: 'Drip',
  [ProductCategoryType.HydroponicSystem]: 'Hydro',
  [ProductCategoryType.Controller]: 'Contr',
  [ProductCategoryType.CO2Generator]: 'CO2',
  [ProductCategoryType.Lamp]: 'Lamp',
  [ProductCategoryType.Medium]: 'Med',
  [ProductCategoryType.Nutrient]: 'Nutr',
  [ProductCategoryType.Seed]: 'Seed',
  [ProductCategoryType.Substrate]: 'Subst',
};
  



// Localization keys
