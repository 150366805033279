
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as styI0f034Iv7U27NRvG5BazL2tycVc77VoAtrSfkKIyUYMeta } from "/app/pages/st.vue?macro=true";
import { default as faqpXvNV3IVqZ7r7EZ6X3TxlTw6jDWpvHRhXzG2SNVEy_4Meta } from "/app/pages/faq.vue?macro=true";
import { default as about3BeDYJHafdz3_450F8I84XDeTlPVmsANEPp0qlI_45udm9EMeta } from "/app/pages/about.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as terms1wCL_jcKgXyjae_4516VMbFrKHvZd0F4Waht3oN_MqrwYMeta } from "/app/pages/terms.vue?macro=true";
import { default as policyHyFEmCLoA_gbds_45pFYhSvKcu4HdwgUBD6ogvbj_45r9y0Meta } from "/app/pages/policy.vue?macro=true";
import { default as widget_x2ASe7yWDPejadtP03YmgdNFLMO3EZxYNWEqcb_QUcMeta } from "/app/pages/widget.vue?macro=true";
import { default as contactufjgRVR80M94tPXoOoARnsatRQ8kqFsigR6NqG_45FbxIMeta } from "/app/pages/contact.vue?macro=true";
import { default as partnerVSbXWg7OoN3298Yq5d_KM2NBjkGsrQufBS40E4Km_GUMeta } from "/app/pages/partner.vue?macro=true";
import { default as premiumiyKcaLvWwU0etgJ5_45iGnWSK46pmxux2Fjdl3VBB8nrgMeta } from "/app/pages/premium.vue?macro=true";
import { default as signinr_rYGcquUCtCe6UaCroPiKQxhVVecQ8xFrQzqubNzVsMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as signupsTWjhAgrrNIHjZDxwXTWWZ6X6mjwk7GNfKEQ1VBvxXYMeta } from "/app/pages/auth/signup.vue?macro=true";
import { default as index_45Ob6G8AnyIFRqcaIVFJRq2340rxPjaOAx_45w_bYZ1cbYMeta } from "/app/pages/awards/index.vue?macro=true";
import { default as index82PdGzQASnwERP_45cEUQRPwYs_jArxUNEGzB3VSwnae0Meta } from "/app/pages/shorts/index.vue?macro=true";
import { default as externalWMXQuMcbHLDgwrMkYyF3KqCAT_9f3z4LIFI1ASwnuPEMeta } from "/app/pages/auth/external.vue?macro=true";
import { default as indexOLgqPskOOcnfxSpHdZYuF_45Az9BNSd71HVfeQsQ8j8X8Meta } from "/app/pages/growers/index.vue?macro=true";
import { default as index_45YqZdB_VzlZy8_45sj5tCG87p_fqHr5B_455V1EEKSka25kMeta } from "/app/pages/journal/index.vue?macro=true";
import { default as indexlGLNzboHlHkxNjK37JjBh1HOrAGAM16O5rcjbqnHVf8Meta } from "/app/pages/strains/index.vue?macro=true";
import { default as index05NxXqLRjxIjIHqrxBauGaOvsw2TF1ieFc_SB6KUzYEMeta } from "/app/pages/seedbank/index.vue?macro=true";
import { default as _91_46_46_46tag_93EM72j9hN8HNI6nJIMo0wCMFVPA2kLvD1JA3Ro67X_JMMeta } from "/app/pages/seeds/[...tag].vue?macro=true";
import { default as indexIM8QU8Z7DMTofxZ_1G5KaRjb28_QD30trKlqHpb9AMIMeta } from "/app/pages/equipment/index.vue?macro=true";
import { default as index1oTafZwSEYJYFyrg2Fb5skY45EIc6_45YTjIWoyWcffDgMeta } from "/app/pages/giveaways/index.vue?macro=true";
import { default as indexpVEzjecirUAPRfx3yZx6T_45J3UpA_SRqdkrSeMkbl7wkMeta } from "/app/pages/nutrients/index.vue?macro=true";
import { default as unsubscribe3Yl0fI6xzOf4znDQWHGiAYKJJWhQYKJyoJv2A6FtxVAMeta } from "/app/pages/auth/unsubscribe.vue?macro=true";
import { default as _91_46_46_46tag_93N7j8d_45im3NVioITfUFDabmJaa90O8GjL2zW5GIfwr1gMeta } from "/app/pages/explore/[...tag].vue?macro=true";
import { default as indexp0yg1CnKOiVZ5JUP0s3Qm1a7CQFQhePC06hDFpKa15wMeta } from "/app/pages/grow-boxes/index.vue?macro=true";
import { default as index3X7uBU7bnJk_45I7HnJMltoSEwPP21zULe4H1AfeVSY4EMeta } from "/app/pages/grow-tents/index.vue?macro=true";
import { default as _91id_93xQqGJgpPLggNFR6Q2sq23wVcIB_Y_45tiiQKCMrI8O3hkMeta } from "/app/pages/shorts/edit/[id].vue?macro=true";
import { default as indexamRCH8twlmSYmD08odBKMKQ1bK2v6rk9MTqUw2qDrgcMeta } from "/app/pages/substrates/index.vue?macro=true";
import { default as indexEdP4F0twbyewgpDKW2bZIOFLfFaG40gKSLqsXq_gEhUMeta } from "/app/pages/video/[id]/index.vue?macro=true";
import { default as indexyNfiBI2BtCYtaZ1Ip4vq5tjaOOCt5VXmSoW_45tmXoq5sMeta } from "/app/pages/air-filters/index.vue?macro=true";
import { default as indexTM3Vrj4qdrjTc23SLVb0fkzCR5bcT8zNEDVL92jeQwYMeta } from "/app/pages/controllers/index.vue?macro=true";
import { default as _91id_932PqJHAC2LP60YU3A1nKuMBo9jDxPygcQV_45N9wFkjtHYMeta } from "/app/pages/diaries/edit/[id].vue?macro=true";
import { default as indexZo_45uLKQ9v2K5bGJp7Px2Xo4GlVvdPHNeuWSFtyy4_foMeta } from "/app/pages/grow-lights/index.vue?macro=true";
import { default as indexob8gmrjJqkFzWXulOUsD7hN28j_XZNl_0pRWskXPOgUMeta } from "/app/pages/grower/[id]/index/index.vue?macro=true";
import { default as diariesYmVi1D9J9mwl660_KOrL0du5EoFaxVqjpPf_TE_WEbEMeta } from "/app/pages/grower/[id]/index/diaries.vue?macro=true";
import { default as reviewsU3ir_45yQpSBPJd2HAhfHW0WBBNzC8BQ07hGyj6BLpTf8Meta } from "/app/pages/grower/[id]/index/reviews.vue?macro=true";
import { default as activityUl0z_u_HxXB2wpwhaJR1UkPHuJsPB9w_g2yRxJcxX2AMeta } from "/app/pages/grower/[id]/index/activity.vue?macro=true";
import { default as followers3QpBOGwFwk3_yWLnKXOHNIR4FFJ4HdmE2gdRpEjURO0Meta } from "/app/pages/grower/[id]/index/followers.vue?macro=true";
import { default as followingR2JMerO4Fi1yuj7qK_oiqLrOmhenjj2c6wZjN4JvC_45sMeta } from "/app/pages/grower/[id]/index/following.vue?macro=true";
import { default as indextCA8snKTTKQNZm99DwgnzZNi5D2IrRQ0vZHhpW3_45_45MMMeta } from "/app/pages/grower/[id]/index/shorts/index.vue?macro=true";
import { default as indexG7ZQo7DfQ_45qkhEhoBUdBi0qNC8oOF5iM2bMbs_aw5mkMeta } from "/app/pages/grower/[id]/index.vue?macro=true";
import { default as index73upi0SZivo5sEUihkfMhGNpF4DCoAChBXRxjlGqsUEMeta } from "/app/pages/shorts/[id]/index.vue?macro=true";
import { default as confirm_45emailzV_rfI7k9jea8K2qyoH2Vls4MI67frN2bOwDmuBgQvEMeta } from "/app/pages/auth/confirm-email.vue?macro=true";
import { default as indexwVLdol8Y4_gFL_45Rf6THWZEvmZYbhjMcWm4wSk5ru8oMMeta } from "/app/pages/diaries/[id]/index/index.vue?macro=true";
import { default as _91week_93L_45iuE3PWQ30nqJd4wLCG_WWo2DgYYx1Egw5_459Vel_45QwMeta } from "/app/pages/diaries/[id]/index/week/[week].vue?macro=true";
import { default as _91week_93iphMdgLrN8zScG3_45Se6xjWWzRG4dUpq2Qmjjk4zm8AAMeta } from "/app/pages/diaries/[id]/index/edit/week/[week].vue?macro=true";
import { default as _91week_93EcU2CFTndl_iqXqy7jykuAiL6YaZ8bP9q12dr_oVlikMeta } from "/app/pages/diaries/[id]/index/edit/smoke/[week].vue?macro=true";
import { default as editl0UIg4HPEVodVoY6MDFT3JpEq2b9NLD7Liq08UjeZkoMeta } from "/app/pages/diaries/[id]/index/weeks/[week]/edit.vue?macro=true";
import { default as smokesL_YL6OhDXd4fL2WSiyxmQFoR623AHtPmjhlb3onEY4Meta } from "/app/pages/diaries/[id]/index/weeks/[week]/smoke.vue?macro=true";
import { default as indexc8z8zB12V_UtG6nbUuUDBo5byy18r_451xxVgaOuXiX2EMeta } from "/app/pages/diaries/[id]/index.vue?macro=true";
import { default as indexPA7jPhDcA_OgoAkx7VuxzkF1tcBNq2MaCZbJ44nJYYYMeta } from "/app/pages/drip-systems/index.vue?macro=true";
import { default as indexJw2axH_45QLAgsuZYpkPejInXJPLyAtLcehLAS7VaXKTYMeta } from "/app/pages/strains/[id]/index/index.vue?macro=true";
import { default as diariesO_45_Vibf2VdVr21x5TaNQeyCByH8ziwHrEiLVdrHHukMMeta } from "/app/pages/strains/[id]/index/diaries.vue?macro=true";
import { default as galleryWr_4545Q6w_FjmAd_45NA0cljQhilZKc773OqkP94r8qw7oMeta } from "/app/pages/strains/[id]/index/gallery.vue?macro=true";
import { default as growers3Lhda_45YgYU3n8niuYFxzyzyC3b_45JDIDN0i0cWwi7DCMMeta } from "/app/pages/strains/[id]/index/growers.vue?macro=true";
import { default as reviewsd5NOa1wi72uWECsowZ_45r6C9rB8ldMCdv_MHv9f1tcvYMeta } from "/app/pages/strains/[id]/index/reviews.vue?macro=true";
import { default as harvestsvcxbcvbmWf0rcAhYlSOckvpf5QkBSJh0lL897xgwZ44Meta } from "/app/pages/strains/[id]/index/harvests.vue?macro=true";
import { default as productsPE7QgTOsZnKqziuc_gySbRFatJh0q6G5dDwWFs_EsV4Meta } from "/app/pages/strains/[id]/index/products.vue?macro=true";
import { default as indexhBF5nXiBQtIRtOJkoiXgJZE_3Zae_KefXiTxml18oAMMeta } from "/app/pages/strains/[id]/index.vue?macro=true";
import { default as reset_45passwordNpUsiQybcTLi_45Ke3wh24gyldOA9IXhGjC1yM32hGDDMMeta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as indexeAJqjDmKKITA7Bb0DxsEMDY4_NeOS13Xb86TIeKKEuYMeta } from "/app/pages/awards/[year]/index.vue?macro=true";
import { default as indexsu8AaMvXrMzcb2GTw8N1m5r_CCB97L54A2myiH5BTNUMeta } from "/app/pages/grow-journals/index.vue?macro=true";
import { default as indexSpyEnJMnwNB6_Ko7skVX5gmsgdLqrFdhp1UV4f6BY7sMeta } from "/app/pages/journal/[var]/index.vue?macro=true";
import { default as indexnkUd8HEb8oCpl04rQjvr_45nVF1wEVNOJtfKfuPcf3YL8Meta } from "/app/pages/notifications/index.vue?macro=true";
import { default as index1YYyALdEuEGIHrMviA43wq69XqaGVAN0G_45Owm59SHq4Meta } from "/app/pages/seedbank/[id]/index/index.vue?macro=true";
import { default as diariesgOKEA3qTFd1lhZN4BczrJsIobfrog6_45DInNJn_ZEt1kMeta } from "/app/pages/seedbank/[id]/index/diaries.vue?macro=true";
import { default as galleryujiaPEm68fLsE9RUlzFjr_c1VZJlwmE1QYxu95RhygQMeta } from "/app/pages/seedbank/[id]/index/gallery.vue?macro=true";
import { default as growersQuuRoOzsWDt89wHlxoAL0_NqzkuQvSR3Xd4yA5w8oKEMeta } from "/app/pages/seedbank/[id]/index/growers.vue?macro=true";
import { default as reviewsOtmDWfi0umC2JvEjRLuU0nZ8GGgNfmJsMHktNkw0U7YMeta } from "/app/pages/seedbank/[id]/index/reviews.vue?macro=true";
import { default as strainsHV3K_5tdogrvhc5AR9yBBtqVc8gFyGtQjQfw44nPHHEMeta } from "/app/pages/seedbank/[id]/index/strains.vue?macro=true";
import { default as harvestsI0_K_45OnSVndLlUp254PY3z1qXvV_VWGJoaTSigoLiMAMeta } from "/app/pages/seedbank/[id]/index/harvests.vue?macro=true";
import { default as indexMaHcNiJ7BD34uquYcLtDROwerAPfLj3lKlqba_45wioXMMeta } from "/app/pages/seedbank/[id]/index.vue?macro=true";
import { default as forgot_45passwordCSWbKn9J3J_B220JzyKrtw7YYrymJGKEwS0HPhIK87AMeta } from "/app/pages/auth/forgot-password.vue?macro=true";
import { default as editua2vlN_Cq43gRO3cYZnFMlndLyhYh173oa1oVgCQyM4Meta } from "/app/pages/brands-manager/index/edit.vue?macro=true";
import { default as indexaYyG6b090TdSKw4wOozryKusIbP_hE5mo0xcJ_45B7ViwMeta } from "/app/pages/brands-manager/index/index.vue?macro=true";
import { default as banners1qJ12xuGNWAwKJcgMQUydQCnb6DbMfSSHhEmmEZcDCgMeta } from "/app/pages/brands-manager/index/banners.vue?macro=true";
import { default as schemesIsRjmVML13Dh8tUXm_45yUKUiHuNBudIhzOep5pai4BqkMeta } from "/app/pages/brands-manager/index/schemes.vue?macro=true";
import { default as testerssQ0gyfqEx4xhDvK5j1Q1xKPkDJGIP9EB3ChZRVs705QMeta } from "/app/pages/brands-manager/index/testers.vue?macro=true";
import { default as welcomed5J6waiAC4NFvj_EMga8jrbZ0RcDZsp_KJeJSFZiDAEMeta } from "/app/pages/brands-manager/index/welcome.vue?macro=true";
import { default as productssSdYuGvzR7zd40kCz_450A800_457nA2mjnDgMavX5FfMYgMeta } from "/app/pages/brands-manager/index/products.vue?macro=true";
import { default as statistics8KGOpy00m7V46Pk8J_45mkpEml7HjZ1TCr7pUwEtvp3aEMeta } from "/app/pages/brands-manager/index/statistics.vue?macro=true";
import { default as newslettersgXrgdAUGSDuBVGEVCj_45ABYThTxc5L2Irr2eNaF6HGIoMeta } from "/app/pages/brands-manager/index/newsletters.vue?macro=true";
import { default as welcome_45oldecPqkKZqpx5xIMgiu_45FEUiNNCgiNmoIN1C_lpFry9iAMeta } from "/app/pages/brands-manager/index/welcome-old.vue?macro=true";
import { default as subscriptionpcx0vCEAN9JPKOg6_45GqMibIRm4vAz_DjTJC46YXPG_45gMeta } from "/app/pages/brands-manager/index/subscription.vue?macro=true";
import { default as index4TD_DHOlqni2bIO52YuclREy4uL22KKGTS7S7aFDQMUMeta } from "/app/pages/brands-manager/index.vue?macro=true";
import { default as indextopBAdQirSo0B4TWdk_hCo5efFoqoCbFq3wD5gxyHhcMeta } from "/app/pages/co2-generators/index.vue?macro=true";
import { default as index4gr4Wcup7uOxdoTcj6VIjOuqVUdjUhXtON7N0yU7YcMMeta } from "/app/pages/equipment/[id]/index/index.vue?macro=true";
import { default as diariesKhvtOCk0SunyB53AlO1mlmyqti9MZZE9W3TL0PbTf_kMeta } from "/app/pages/equipment/[id]/index/diaries.vue?macro=true";
import { default as growersd4L5bEjaxnZZCNxWXMwXibN3esxgYzxstvZQm_QvzOcMeta } from "/app/pages/equipment/[id]/index/growers.vue?macro=true";
import { default as reviewsWr145ayXGQKQ_uOCQuK6hTFVI_457eT6gKJ0KHVZjoDKAMeta } from "/app/pages/equipment/[id]/index/reviews.vue?macro=true";
import { default as productsb2a8mH7vFJNp1uijhd4jxgzuZyiEsvlK4f2llbI7qecMeta } from "/app/pages/equipment/[id]/index/products.vue?macro=true";
import { default as indexZ4Q3DnG_45VCeOoBHDjV0CejnkcNnPexnMWXmzFdyIMvkMeta } from "/app/pages/equipment/[id]/index.vue?macro=true";
import { default as index6Gy5i1DRdS1DulNr59dU_45QgcY_45t4oECEbfNe5IY3BJEMeta } from "/app/pages/journal/voting/index.vue?macro=true";
import { default as indexIFN7g_450Uq2GbCs6BGKYJB1u21JSj9Wy2vgHXmj200mwMeta } from "/app/pages/nutrients/[id]/index/index.vue?macro=true";
import { default as diariesOfmPsc93wfIH_UvFrFISv_r_pRBVxbebcaKxQ5QOoxkMeta } from "/app/pages/nutrients/[id]/index/diaries.vue?macro=true";
import { default as growersZu2HUG1zTL6LePaiTqLLqkzEMcLNwYdozc_45fdGe6UlAMeta } from "/app/pages/nutrients/[id]/index/growers.vue?macro=true";
import { default as reviewsYdMFD6_45oU0f3rIx1xbUFp4XI4Mp6rfPHlNdTjuhQMMIMeta } from "/app/pages/nutrients/[id]/index/reviews.vue?macro=true";
import { default as schemes_450ArjeaaP8DJn9uFUg621eCEgkU95le8xBTcAGGqaK0Meta } from "/app/pages/nutrients/[id]/index/schemes.vue?macro=true";
import { default as products32yT20b6w6SC0IkgMXK6Sc_45H77kxE4aRL_45GLsOBBRaUMeta } from "/app/pages/nutrients/[id]/index/products.vue?macro=true";
import { default as indexSbYznR0gEYPQrbxeGxvm7GiRgJtmaloGgK_45u19kArT0Meta } from "/app/pages/nutrients/[id]/index.vue?macro=true";
import { default as index5vDgC0Z_GmPpr9iYMtUEO39D1NpMC_45ysv1LV_ImD7pkMeta } from "/app/pages/giveaways/[var]/index.vue?macro=true";
import { default as indexbbzEFN5Yb_45FyjOUSnIMpDcys1fdiblSDN_zgaq8U1ucMeta } from "/app/pages/grow-boxes/[id]/index/index.vue?macro=true";
import { default as diariesdMBsD10A0dWcoSD8K1mcmsLODBjpBk1193QDddiJKDcMeta } from "/app/pages/grow-boxes/[id]/index/diaries.vue?macro=true";
import { default as growersSOeyhpYwngtKTY62YI0_45S6UUK_45WlyfqmJU_B3B224cgMeta } from "/app/pages/grow-boxes/[id]/index/growers.vue?macro=true";
import { default as reviewswP6cKTwA2aMDe7K4FKWs8pgciScmzklec5vqGgLuuggMeta } from "/app/pages/grow-boxes/[id]/index/reviews.vue?macro=true";
import { default as productsQeA3l1tqU308I9RNWvJ7DrWwdf0pjVdBmQzWraibNy4Meta } from "/app/pages/grow-boxes/[id]/index/products.vue?macro=true";
import { default as indexQNN_wMnh0ijoo9UKUWQw7HHca5p3ZnTkCZEjr7zx2LYMeta } from "/app/pages/grow-boxes/[id]/index.vue?macro=true";
import { default as indexMagM7TpiIbfVaAJvTD1WnZgwbhdVcObpOC_5nioDzLoMeta } from "/app/pages/grow-tents/[id]/index/index.vue?macro=true";
import { default as diarieswTk8TkztzcXtWFwA_Q_hM1bVATc7VjzEoTwqGy8Nce4Meta } from "/app/pages/grow-tents/[id]/index/diaries.vue?macro=true";
import { default as growersE7KiWk9_GnOHuv7uj6LOm8XzNmzzdY0b95SqWnov_451EMeta } from "/app/pages/grow-tents/[id]/index/growers.vue?macro=true";
import { default as reviewsHe1DXzHqH6kw05f7y_gpcMlKpz_KBq4WP92CEAFflsgMeta } from "/app/pages/grow-tents/[id]/index/reviews.vue?macro=true";
import { default as productsKzVFTMwwsOzwVlIQsYQnynmuEwBPItGaoGhXAKZws64Meta } from "/app/pages/grow-tents/[id]/index/products.vue?macro=true";
import { default as index6JnImZ8P_D88PKmuWjBrUGa3hUOoFEgY6wR8iF_yP_UMeta } from "/app/pages/grow-tents/[id]/index.vue?macro=true";
import { default as indexlLMItQ6m2bH4rUo3j4wO2_kmCyy7PCJAbFZYdTjXuAUMeta } from "/app/pages/substrates/[id]/index/index.vue?macro=true";
import { default as diarieskzcqwIEHkNjn1DKJXuXMKH8uI6QsCXs6ixz2905QLUwMeta } from "/app/pages/substrates/[id]/index/diaries.vue?macro=true";
import { default as growersrTGeSGaQUTQPfSnXHMfegNg1kQX5vDTxFSxkes6wRGsMeta } from "/app/pages/substrates/[id]/index/growers.vue?macro=true";
import { default as reviewseUdLiqC_7vqQHGdgQj4WrGOw8O827SduJCVoPRhHUI8Meta } from "/app/pages/substrates/[id]/index/reviews.vue?macro=true";
import { default as productsfi_LP2okqtEYA68E8jwRCYEstcG33ckrLEmPCjNZuJkMeta } from "/app/pages/substrates/[id]/index/products.vue?macro=true";
import { default as indexiakswyEv6n_zTxoEUEpSrTF8gFJahJTuum7BnGleqScMeta } from "/app/pages/substrates/[id]/index.vue?macro=true";
import { default as indexgJs6umToeEbqK_dSYnhwCkAlSK1696kp9jlur9YWj_YMeta } from "/app/pages/air-conditioners/index.vue?macro=true";
import { default as index0Kz7S79J3yjae_45UVb_wdTw0Xqlv2lbhraqmKoKSrfvwMeta } from "/app/pages/air-filters/[id]/index/index.vue?macro=true";
import { default as diariesvGuMw6T8TqMtoNMGhOgFdt1cTEyD6_45gi7m_rqInQbPMMeta } from "/app/pages/air-filters/[id]/index/diaries.vue?macro=true";
import { default as growerstms821fZkCC0ul3IuumPArGZEn_452XqQCDekPglFTmzoMeta } from "/app/pages/air-filters/[id]/index/growers.vue?macro=true";
import { default as reviewsCLfBB_UQYEMKYV93OFpqn20V1cPbeMDYAnlprDCCOKQMeta } from "/app/pages/air-filters/[id]/index/reviews.vue?macro=true";
import { default as products9mg_45aSs7HM5B659_454BWIgVaEwwpoL9bRRnvUdoIoL8QMeta } from "/app/pages/air-filters/[id]/index/products.vue?macro=true";
import { default as indexnoR6X3OU8v4T_45u_45PAfd40war2NdcRzbQ2AzbPGOfpdMMeta } from "/app/pages/air-filters/[id]/index.vue?macro=true";
import { default as indexMRcABLODlOAPfCGlr3WvxDHh9Qnykwg_Hq7_DErof4wMeta } from "/app/pages/controllers/[id]/index/index.vue?macro=true";
import { default as diariesJtBL34m_t55qVW0ZcL3XHBY__45txRDEGqPYZi4vEBPOQMeta } from "/app/pages/controllers/[id]/index/diaries.vue?macro=true";
import { default as growersCtjP_Kf2uPDYwSQH_4CqvDNMbsVAM06E3PhXZbPpOyIMeta } from "/app/pages/controllers/[id]/index/growers.vue?macro=true";
import { default as reviewsTewh7mAgHaBMT8Ef6Bdw5B3_45T0O5KfxLpw8Zz6vl1ZoMeta } from "/app/pages/controllers/[id]/index/reviews.vue?macro=true";
import { default as productsj703bV9l3HhlRuGi0YjEtWFpVFsILPq5gSTn225deU4Meta } from "/app/pages/controllers/[id]/index/products.vue?macro=true";
import { default as indexJGE_G5Zu2XoOWPjKKjsydVOmQyTw8r6nOOXbsx_451UCYMeta } from "/app/pages/controllers/[id]/index.vue?macro=true";
import { default as index_45m0r2F1hzb_fmfb_45pm98lQIU7CHnT8LsSKS_k2anO_0Meta } from "/app/pages/external2/rating/index.vue?macro=true";
import { default as indexQDLc8WTI6joDCPk_45oeLnvZvj1tUgTjpJkTsHf_45eiArwMeta } from "/app/pages/grow-lights/[id]/index/index.vue?macro=true";
import { default as diariesTXLTign6QH0q6iYRolnviY7JsKWlEi_45ubaXfTfC2RAoMeta } from "/app/pages/grow-lights/[id]/index/diaries.vue?macro=true";
import { default as growersLL5L_450cP3Z5HF_45xFXE5l78ViRL21hzRqhpGjMXav0wUMeta } from "/app/pages/grow-lights/[id]/index/growers.vue?macro=true";
import { default as reviewsfESVPRUHOeDe6K3LU20Dl9mqNMm_nqTi51vRAXzM53YMeta } from "/app/pages/grow-lights/[id]/index/reviews.vue?macro=true";
import { default as productsiNga7tx_45Y_453VALKcK5KYRLaMOk2_45fF8TwrFvqyuv4hUMeta } from "/app/pages/grow-lights/[id]/index/products.vue?macro=true";
import { default as indexXlUQwFKd2YvnpyWMEhqTLj4nP71r2g_45BuK_TmXAQkcoMeta } from "/app/pages/grow-lights/[id]/index.vue?macro=true";
import { default as indexKFrwM1pXO8p17K9EduOU_45h_45K31QE16CCy8VlvDi28zUMeta } from "/app/pages/ventilation-fans/index.vue?macro=true";
import { default as indexhDbCtQIZICBv_ehBngTZRUp87_45EbN7CZ29wtCtWBts0Meta } from "/app/pages/drip-systems/[id]/index/index.vue?macro=true";
import { default as diaries2_Gal3QCzPAb8EkzdxeqVN5Crug7ZqhzpXbAimWcLtAMeta } from "/app/pages/drip-systems/[id]/index/diaries.vue?macro=true";
import { default as growersZnB7_45e5ZjPSLYQ0wb9Qu3BwhJTKnbDgi39Jg45XCjwIMeta } from "/app/pages/drip-systems/[id]/index/growers.vue?macro=true";
import { default as reviewsd1W5Q_4538eU_456TZ_5r5LgcF9bBNP52Xz27Up0jReqtfkMeta } from "/app/pages/drip-systems/[id]/index/reviews.vue?macro=true";
import { default as productsPrGUFTYlbEJnHXxcToi6kxbjnb6niptGInYCq0zJz1gMeta } from "/app/pages/drip-systems/[id]/index/products.vue?macro=true";
import { default as indexT42EULBix_fMug4wRagVFfB5xxHXCZV4N8ktoDhPDioMeta } from "/app/pages/drip-systems/[id]/index.vue?macro=true";
import { default as _91_46_46_46tag_93wQ7qaCQfMhJVajnIUFAYwsyXkD9k8qOxVmY2LOw1d_MMeta } from "/app/pages/grow-questions/[...tag].vue?macro=true";
import { default as utils5x5QBDs7Qy5QXt3seasx3sJa_jquav3lAeZ7k0pbBUkMeta } from "/app/pages/brands-manager/func/utils.js?macro=true";
import { default as HeadXpsUtn958o1E9cM4U9_FDFLBqnfZvjeVKx7q3nT_wR0Meta } from "/app/pages/brands-manager/view/Head.vue?macro=true";
import { default as _91id_93wxrWrwYJ49Bs0Yc4nD2_45O7h_45vSdoEasit0ljwyyYDNEMeta } from "/app/pages/grow-questions/edit/[id].vue?macro=true";
import { default as indexCZrDJ5UCuvq5cXx1gcDJ8F2KBwqYVhp6ejyLFRXiQQsMeta } from "/app/pages/hydroponic-systems/index.vue?macro=true";
import { default as index9nIxI86_45zNTUrwfYyYf16l_45RAdVsYjECaSZHSPxOqBsMeta } from "/app/pages/journal/tags/[var]/index.vue?macro=true";
import { default as index0nWoQsYfdGKRzpu80Kj0Bmy7_45vNmRK2DhuIImO4HBEUMeta } from "/app/pages/co2-generators/[id]/index/index.vue?macro=true";
import { default as diariesglsAXfPFvle06bmtWWZyYzIyrZSyh62143vBV6VG3wYMeta } from "/app/pages/co2-generators/[id]/index/diaries.vue?macro=true";
import { default as growers_453Vu55tUsyAHEhKc_45qnspU65K9uHS9PO6xwaxFChkM4Meta } from "/app/pages/co2-generators/[id]/index/growers.vue?macro=true";
import { default as reviewsy5YYuo2oysX0yBENnQcIVAjCVsLuH3RdZP4IU1RQ4iUMeta } from "/app/pages/co2-generators/[id]/index/reviews.vue?macro=true";
import { default as productsU2d_DKA44UC2xvPFmmPowT0chc7OEGjAdOgzX3BaIvEMeta } from "/app/pages/co2-generators/[id]/index/products.vue?macro=true";
import { default as indexjas_7KqjKoZc5z25Wm3OcuBPVJvBX98sMfJhaxC6wA8Meta } from "/app/pages/co2-generators/[id]/index.vue?macro=true";
import { default as _91_46_46_46strain_93JcJux7Njw_45_4505K38IVDGz7xwoBqy4iZy_8e2eLonTsAMeta } from "/app/pages/grow-journals/[...strain].vue?macro=true";
import { default as indexMD85ZuDlrAnh_45gXgTSZKG53LmBJS1hRMXWCO8e5crroMeta } from "/app/pages/grow-questions/[id]/index.vue?macro=true";
import { default as index2S5_45hwwWM3HMpIrY80JvjJcxJ8HY6Q6YPNfWk5IoExIMeta } from "/app/pages/seedbank/[id]/[id2]/index/index.vue?macro=true";
import { default as diaries7WzfcnnYWrPm_f6HGMFBxh9U6fPPs1Sshh0lFvNO_4558Meta } from "/app/pages/seedbank/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallery3pu2mtDVMl_IEHYPhghQR8RrZA5Mhgt86yGz9OYeOIUMeta } from "/app/pages/seedbank/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersC0__HsQ8owo0OGAtJUCIaaPV1v6B_qJ8NKpVWVQwypYMeta } from "/app/pages/seedbank/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviews_451tzxioa8vSnx1ravlYlY_45NpH8DE4Q5veJPcYRYWQWwMeta } from "/app/pages/seedbank/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsNhcPQeGMPJTdSymJq5GY_DP_r_vWJI29u52m3tKeoqQMeta } from "/app/pages/seedbank/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexU6nD4QzkI8fXm09ZrM_HO0Mzh09hYg77UhKBHznQIJsMeta } from "/app/pages/seedbank/[id]/[id2]/index.vue?macro=true";
import { default as indexODGcbK0vUQWOsBu79xB3rso2NdbrBCLDvA56IgbGJ5AMeta } from "/app/pages/equipment/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesh9Q6XcS4gYa4Mg0Ui5dweRtKLgUCjluiCA9Xdq0izWwMeta } from "/app/pages/equipment/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryAKKrU_45gNwaCsSZwy8gzRrhtmwQkZYt_x_F6luChoC8IMeta } from "/app/pages/equipment/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersQiC12cYZs6yAU6Lww_45UdZ41jjAJ7TZQnI3jnaSKnrOkMeta } from "/app/pages/equipment/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsZbiaj8R2XvpEf74OF49Euaepu5mvkOLCG2pjzgZkwBYMeta } from "/app/pages/equipment/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsMCUcPTm0E_45d07Rx4v1f7v8eVOU9adXzrCp9G8cnSmqgMeta } from "/app/pages/equipment/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexvZrwBuTkk0hzeKTlAXDZFixbEH_456JDODmxPgxuug4gYMeta } from "/app/pages/equipment/[id]/[id2]/index.vue?macro=true";
import { default as index_45YqXVM88iQ5YIj_45aA_45PUKGmAJsoPBfaQyKB_450WXI0QYMeta } from "/app/pages/grower/[id]/settings/index/index.vue?macro=true";
import { default as bannedJCVbvJTNs6EwQK0QCt27SSjYHPCaZ_rNya5T3yfIHxoMeta } from "/app/pages/grower/[id]/settings/index/banned.vue?macro=true";
import { default as deleteyYnhjRB2FOwcnsCKntB9Nn5jCpdZjq9qxQrD6xjF9LgMeta } from "/app/pages/grower/[id]/settings/index/delete.vue?macro=true";
import { default as cookiesZvPBtM6KzXzxvdDrUUy_Xk2IuZEUIcAkhWH61ju_45aa0Meta } from "/app/pages/grower/[id]/settings/index/cookies.vue?macro=true";
import { default as socials86Fqw5Uo3I2tDbG5rjpHrnjNEHnUW6lfVDK5Cx52MTcMeta } from "/app/pages/grower/[id]/settings/index/socials.vue?macro=true";
import { default as password9iOrhQpqEE97IQP4_BUpq8E2pMn3gfnauSFXcVRMyh4Meta } from "/app/pages/grower/[id]/settings/index/password.vue?macro=true";
import { default as indexhDhvRD3qcDbPSW8TrDMQUb_Vyc2Su24tn2cLP0uFO4wMeta } from "/app/pages/grower/[id]/settings/index.vue?macro=true";
import { default as indexWiZCfwOEqXPSv6tjN1ouLy4Ua7lsEP85rvdY0lptvy0Meta } from "/app/pages/journal/voting/[var]/index.vue?macro=true";
import { default as index_45xcHDgVhOB4Fopyjg3QHE5mifQ9AhpFgP5vR7Fch1NIMeta } from "/app/pages/nutrients/[id]/[id2]/index/index.vue?macro=true";
import { default as diaries8kOIkVCgv7_yCSncs_4550rCCwZCW6MG1U7FZEkb4agm4Meta } from "/app/pages/nutrients/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallerywrMDVbaFZfs60jjCPCDQSd8_45XV_1QlS_45dtkvfgWx_akMeta } from "/app/pages/nutrients/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growerspCIWAT6lCcjTsg2bLGfJz_45R0GeWZJ7fbuoxsqFFEa8gMeta } from "/app/pages/nutrients/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviews4GOONxAL2H0detyZE3vLl0W2P3j60hxRk5UwsWHix_45UMeta } from "/app/pages/nutrients/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsMeY8iSoBNgtM22heKJB2na7ZyZjW78ETUZ3I91_5jz4Meta } from "/app/pages/nutrients/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexzEhXhEh9tNDkbRZ0ii33OmadVtF53N25PL0ujeDQg24Meta } from "/app/pages/nutrients/[id]/[id2]/index.vue?macro=true";
import { default as indexKxGHVZSNy4ggxFagrIx_45Z7Zo20TLCzwuzsKZvPNP90cMeta } from "/app/pages/air-conditioners/[id]/index/index.vue?macro=true";
import { default as diaries5UhZEIguG9BYxRF8kvbXCoNBtDvN0qblV1yhsp7zQBEMeta } from "/app/pages/air-conditioners/[id]/index/diaries.vue?macro=true";
import { default as growers2OIL8Wq_45zLXfCU_nE4YJMqSqgONomv3ds17n870y4nUMeta } from "/app/pages/air-conditioners/[id]/index/growers.vue?macro=true";
import { default as reviewsNPqDqSC_z_RDoMLcO_45o1ex4zzmQIso5lexraWNqV6bUMeta } from "/app/pages/air-conditioners/[id]/index/reviews.vue?macro=true";
import { default as productsOu1QSqWDbdojhIJ3I9Byo1CV2dh_45bjT8NJCf83GY_45g4Meta } from "/app/pages/air-conditioners/[id]/index/products.vue?macro=true";
import { default as indexc6Sk5jyYBOAWw5psLKrXSQt6GRpwie39D8A3u6osPuUMeta } from "/app/pages/air-conditioners/[id]/index.vue?macro=true";
import { default as indexdgJQhhRrQSTtrSRe0cdeUy5dDT9BiMQpBkEvJ5Sq9zYMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesj_45a8mEvVXdIYR_45O_anSquXyiFnOVOl0eCnuDAkRFH3cMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryYjXD4rFvOc5_45xyfPJzi8G93efmzKSiU7ZOp5b5ITT4IMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersMbmVmovNUHVujdpEJgqqaVA0lB_456r2AQiuynV8pR31sMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsY0FP_5RhPK_45WvdwWW1Z5um1XEyKt1BKOcNUFx_qOnqgMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvests1c2T0WuYm0F_45C_455Q3HO7wru6Xxw2yrOQc1GIX7lTv_0Meta } from "/app/pages/grow-boxes/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexUUobWqmvi13x61WIcd218SRKfwCFfW4mvc4wowsxEJMMeta } from "/app/pages/grow-boxes/[id]/[id2]/index.vue?macro=true";
import { default as indexXSVichHwdx0vU8_OfCNs98jPCXkpr_9jYCtjG68jxzsMeta } from "/app/pages/grow-tents/[id]/[id2]/index/index.vue?macro=true";
import { default as diarieswGTvxcNk76pBL6iA9ETeghK1yFwu_45cmzx4IANqnx640Meta } from "/app/pages/grow-tents/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryw6_i09t7NIbXjLmglvPsxb8tGcFPmn7appEnLibFz94Meta } from "/app/pages/grow-tents/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growers8uLFugtfa02jn_45hKv90KbGzadTkVByeceaeP6bV_WSMMeta } from "/app/pages/grow-tents/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewshban7ymMYc42_MXHYYGohnxj_0CUAVmGyordEHBVJjgMeta } from "/app/pages/grow-tents/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsyzNwLg5wp3zWV38ekEMx39vN_458LXbr2XkDTrjx_Kx0oMeta } from "/app/pages/grow-tents/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexwe46edHeTapP_FUcmB39mPOxXH_45Vd_e3UuvXipVj62gMeta } from "/app/pages/grow-tents/[id]/[id2]/index.vue?macro=true";
import { default as index8MHzxRbG1Pf_cZGWnAn9DDRloisld83M1plNFQcU_45jkMeta } from "/app/pages/substrates/[id]/[id2]/index/index.vue?macro=true";
import { default as diariestWj3pnsS52f9_izY3m6zlQJjyMU9huRkooNiGGSa_45_gMeta } from "/app/pages/substrates/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryJ0usoymQ9afq1_45rEUQslcbPjGj6yxRRckvFCQFIW4hMMeta } from "/app/pages/substrates/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersNeSAojYlgnJvEs1k2eQ9jUECfWs5JvjIF8BmpxPxk_kMeta } from "/app/pages/substrates/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsaEDrCMCIdmm9EtKf_45DIRaNk_VvUFHjg_45sLq7SBG5C3QMeta } from "/app/pages/substrates/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsGJsjSgCzTGPchRTFAEcnIncBnj37H0Td7_fKVzb87_YMeta } from "/app/pages/substrates/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexLMnnWdco_RcE8weAQMwZbbqMNoBgvHQA32u_j7sdQkkMeta } from "/app/pages/substrates/[id]/[id2]/index.vue?macro=true";
import { default as indexFam1LHLyke56dGKY2vQZjODrnd01xgumqi8PtDWvjmQMeta } from "/app/pages/ventilation-fans/[id]/index/index.vue?macro=true";
import { default as diariesim9D9jPiMIhAuG_p_45pUsCI1dMjjzq_EWMruG68JuMEAMeta } from "/app/pages/ventilation-fans/[id]/index/diaries.vue?macro=true";
import { default as growersqdn4U4V1oFIRg8_45_NwdO_X5QypJS1KPUVSMaSQ3tk5gMeta } from "/app/pages/ventilation-fans/[id]/index/growers.vue?macro=true";
import { default as reviews1jScm5Zh0dSohx4twCHcAwsyVfX8prlL_Q53xu7NLhoMeta } from "/app/pages/ventilation-fans/[id]/index/reviews.vue?macro=true";
import { default as productsX0Om_TDd1Pw9SoxYll7Hl2miCxFc4iMEXwQ9mwC_vLQMeta } from "/app/pages/ventilation-fans/[id]/index/products.vue?macro=true";
import { default as indexuJ1BcJQkbEvaJOR_f91191XbXZyyr54xNuNThCWpRDsMeta } from "/app/pages/ventilation-fans/[id]/index.vue?macro=true";
import { default as index0qrgbnxGKZKdTHBDfjP26PELwE3HRChNtd8T9t90NX4Meta } from "/app/pages/air-filters/[id]/[id2]/index/index.vue?macro=true";
import { default as diaries2MtIpxwauH4Q_45YIWM81zM6oLCVPXZfYrnaHfEWqDcogMeta } from "/app/pages/air-filters/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallery2NZ9jBjK9BR4vyvmf986URGKnUjRi_45Q2RmnV03hc8ewMeta } from "/app/pages/air-filters/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growers3gBOxbxMphRz31o9XL2d_DdvE2YWRscCCz4PZdMMO_45gMeta } from "/app/pages/air-filters/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsag57X1rGlGoIyMpnXupXwk7G5IvPBLVpaFWELXgRXwYMeta } from "/app/pages/air-filters/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsQSjTAVkkDFqQ5Pqwfve7BxgPhTwvuqrrEb9h0oHNDrUMeta } from "/app/pages/air-filters/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexSglm17G0yGOSNPZmzmdRhr8FmwWLUyczKn3Gb1zmiWgMeta } from "/app/pages/air-filters/[id]/[id2]/index.vue?macro=true";
import { default as editBrandq8jQtjJDKvnk1ay4uGQO70SAWMdt6i2Caw_pVOd3QK4Meta } from "/app/pages/brands-manager/func/editBrand.js?macro=true";
import { default as indexT40ls98vJFQIZZ82MuzFMaTbWxdXBp6TcCI5y4TVb7oMeta } from "/app/pages/controllers/[id]/[id2]/index/index.vue?macro=true";
import { default as diaries25cQ_WXZgG1oeUY0iqpX9Pk0SWvB8aXav86Rxi8cengMeta } from "/app/pages/controllers/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallerymedpD7WEJKmaP64r8rhE8jNqt0ysheGw_hV6fu6y7e0Meta } from "/app/pages/controllers/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersL9O51KiXAMSKFCiAdNm798kpwY6mKt0qMAEJNDi_jioMeta } from "/app/pages/controllers/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsBOqMjlV7DU0pzPn7lSdBqEMRg2rs2DHTmJtONxDoQIsMeta } from "/app/pages/controllers/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsC6qBMXY_45LO9pe39FI60cYuy7ewzBZ79Xi_45hv3c7ehGMMeta } from "/app/pages/controllers/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexNdDudvgj9IQyJ9eF_Xc_45wCxe_45IbKF0YQakj0_45N60ZdcMeta } from "/app/pages/controllers/[id]/[id2]/index.vue?macro=true";
import { default as index4_yMKe3wF6HWKBtbvf32IvshCjxlp0rz_Fqyp96JbS8Meta } from "/app/pages/grow-lights/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesLblPu1qZXPmi1bLKKhkfDexWHLx31CE71TvcKFidty0Meta } from "/app/pages/grow-lights/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryNMCUxoMiIqk3yKb1BUVbF39z4aw_7rrNHNH0AghLwosMeta } from "/app/pages/grow-lights/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growershPfcdPR1ZAnjpRKABmiYzSmjj5UqwQokwLmVc3GgQGsMeta } from "/app/pages/grow-lights/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsxm_qEqTWxQR0c0G2MAsYmvBljsuZAsn6r21Ho5Yh0L0Meta } from "/app/pages/grow-lights/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvests6NTFZMthY_Lfb54VKqXHkrIt0dKDW0seVfkWYcYoprEMeta } from "/app/pages/grow-lights/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexDLpUBjpPyDQ8QVIBjHcdpjTFS7g0BtCuM1YqjTjbqmAMeta } from "/app/pages/grow-lights/[id]/[id2]/index.vue?macro=true";
import { default as indexEbTO6a1jYBmKQ6R83564GyZj0LFSbm_gK9f2pkQxxmAMeta } from "/app/pages/journal/category/[var]/index.vue?macro=true";
import { default as editBannerIVQWiErMgdEuR0KI4rkRs6m_8n0cbrRRh0yk2fh74ooMeta } from "/app/pages/brands-manager/func/editBanner.js?macro=true";
import { default as editSchemewzJniOevup6a8S8yFn8E6LtgtGYA6jbxb8cBzsn71GkMeta } from "/app/pages/brands-manager/func/editScheme.js?macro=true";
import { default as indexQWHrG_45IuZCFzB4ueop6pYuExq6mi1PuJhipwHLt_45hxYMeta } from "/app/pages/drip-systems/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesPA3x0dzcRIhq9K_45RmMlbXmylE_Y5GlOBIKL1GoS0b1MMeta } from "/app/pages/drip-systems/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryedJTIMw2sUc_454G6NMFHHBeluZQVCBqcJCfV6x_7_Y4AMeta } from "/app/pages/drip-systems/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersn9IeLBYJDEHWkCE1g1cXvxso2a2LgML1dSC3leGTWVQMeta } from "/app/pages/drip-systems/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewslHgYMyK462Wg_MaMy8VZJwKzIWD6nsn6V72ALGIKLoIMeta } from "/app/pages/drip-systems/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsNSv_45sWKttuhP9UF8s5YHMolbVPjpwNgbPCJ1EPmfWE0Meta } from "/app/pages/drip-systems/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexIoMfhDCGeh6dvgB0qDexiW_45HXUEllb93LYxdN04UlWQMeta } from "/app/pages/drip-systems/[id]/[id2]/index.vue?macro=true";
import { default as indexQSfVWXkGfkky1c1Yed9CJyxIs4iKPiVtJLF4USjlUTIMeta } from "/app/pages/hydroponic-systems/[id]/index/index.vue?macro=true";
import { default as diariesbTyoPaZ8LUdr1Mm2Oro_EXf0zK9Z9p5z_45qBqghO87zwMeta } from "/app/pages/hydroponic-systems/[id]/index/diaries.vue?macro=true";
import { default as growerso4etdrg7yEkdCS69OJO1Xpst1W6I9b1oEBjrvPSIQMEMeta } from "/app/pages/hydroponic-systems/[id]/index/growers.vue?macro=true";
import { default as reviewsLCTt6Q6wJey9YmPszeupLybeY8YYyrhYbXY4IsMbSjEMeta } from "/app/pages/hydroponic-systems/[id]/index/reviews.vue?macro=true";
import { default as productsGdnjGZK9UD7CIh9_fhHqi7M_45H8ABeJJr6i_WPQOKrLQMeta } from "/app/pages/hydroponic-systems/[id]/index/products.vue?macro=true";
import { default as indexwsDzvbX2eWV8LLGn_45eLSpzx_wxzjnCOVTZZeKRP7CngMeta } from "/app/pages/hydroponic-systems/[id]/index.vue?macro=true";
import { default as editProductHNP0z0LluShYUMl_dK14mgYhjUlTtLmsxLm9SZsexU8Meta } from "/app/pages/brands-manager/func/editProduct.js?macro=true";
import { default as Chartdu2uDjIixa3EmEXblZUE8aTNndQ5TvyswgXtTV4ZErQMeta } from "/app/pages/brands-manager/components/Chart.vue?macro=true";
import { default as indexKONRW1UvWxYWlx98mXn0ZpSXp0mQqHJU7kBobL5xFZ8Meta } from "/app/pages/co2-generators/[id]/[id2]/index/index.vue?macro=true";
import { default as diaries3p09nJjh8WvA6vqJLee_45Gzg3LPkSQyhKVxDrW0PWJf4Meta } from "/app/pages/co2-generators/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryt2WQkZCVVk3_6WsWiQJnYsM4M6RUvkS6p25pDwEmRIUMeta } from "/app/pages/co2-generators/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersC3cvWXqM13AJ20IIZlZjICOEILjsy2CmFjs0V0Q_45_IUMeta } from "/app/pages/co2-generators/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsIH00Ba9vtR3mcRW8ehdyml_45y_uRJS6LpNt4lQz7xXL0Meta } from "/app/pages/co2-generators/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvests7NIDe7JENiGXxA6Iy7aLYqX_45_45rlf_wio3Feh52SKfRYMeta } from "/app/pages/co2-generators/[id]/[id2]/index/harvests.vue?macro=true";
import { default as index4vToBULVzgOsbP4MfB0hIcZCk7hidL5H8lvJbsBS378Meta } from "/app/pages/co2-generators/[id]/[id2]/index.vue?macro=true";
import { default as Select_45WENHZ4ZAnQhaz1yFMkYPHjr6Oayoq5CqfIUADbgcnwMeta } from "/app/pages/brands-manager/components/Select.vue?macro=true";
import { default as indextvnkAhdE61i0T9YQboagaIay5v_1X5ZUaFrL_lJKqEUMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesywaXkzxqtoeZ5Tw5cxaOfYhRG5TkRRyFXT6LxmDsRm0Meta } from "/app/pages/air-conditioners/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryYKkRQekAxR8AGGqajkR6Pfy_45hFAIG_45z9s_lBTvMtpV0Meta } from "/app/pages/air-conditioners/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersOW_t2zzjLYjSYx_45X_456gZ5SuNNvfvWV4v9GT_7ZNjuw8Meta } from "/app/pages/air-conditioners/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsQInN1olMtXaNkQ_45luJH9lErHIyVIzU81Zc5gwg0oP_MMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvests6vVOYk4McjCvTKRFI79Fo89qnDUuRH5K6zTBONYiIh4Meta } from "/app/pages/air-conditioners/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexWzcdTOPEH_V8qilT_eoaM0aw_SMyDesAjH_oyJ36tmIMeta } from "/app/pages/air-conditioners/[id]/[id2]/index.vue?macro=true";
import { default as _91val_937sEewSqkWdYcsrzZRFce3Vf1LdJZAWV_yAx2GTZIGM4Meta } from "/app/pages/strains/category/[category]/[val].vue?macro=true";
import { default as indexL2OXBqfOXKI40XFIeyiONCNVNOycV1FbWRr03rdN_45JgMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/index.vue?macro=true";
import { default as diariesS_45TON1y0YSpniOLF87WqpvwYj7njIlTMoecBYGCA6lEMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryWdwjWOPA__rIfa5bfzR8Xpjyi9mXushJu52QC_45Ys7J4Meta } from "/app/pages/ventilation-fans/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growers0hwjog9H3nqsmn30aqIOMDUm59_45m6YRRD_cRcfhctdQMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewslonZ7QOxyBjEFG4PVqZZLtm1VDq8AcTIZi1FyGFgikEMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvests6jLx3PRYUkErUcWv4qy355tSmJCssLVSr4GMSAckuCYMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexGxVfIKXN_usRt435QWA7Pe954bJGHS_45A9crRdnyhTDkMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index.vue?macro=true";
import { default as EditLampIegrYcZJgNJrnCXiQl3PelxrIJKDYIYqDPyxb_456EJh8Meta } from "/app/pages/brands-manager/components/EditLamp.vue?macro=true";
import { default as EditSeedV7vb_JQLVOItoyPOSau3LJrFz2gSU2BGmXx4uri7sgMMeta } from "/app/pages/brands-manager/components/EditSeed.vue?macro=true";
import { default as EditTentwLj__45AsZwLYZPSgMC48o_sA7cJmXEj0baxass4h06sQMeta } from "/app/pages/brands-manager/components/EditTent.vue?macro=true";
import { default as EditBrandgNUsvhKI3XSPg5MnynjxiBl92c_Em8YlTgGlvy6WwmsMeta } from "/app/pages/brands-manager/components/EditBrand.vue?macro=true";
import { default as indexRKCYcgd3riOCaJXVtrTkq4B069VY4zHEhXZTdEAFsVQMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/index.vue?macro=true";
import { default as diarieszTE7TFqWijTZ4Nkef72DAvf7KYR5qq6KLYxwQc0nL1wMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryRO2N9GOO4Ee0sTBVL0HoWFEE_x8LPJSfZyxTGA1_45Bl8Meta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersWYdHlochnuZRxBECu2HF3l_SzDbEJdTMQt6yjNKW1GYMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/growers.vue?macro=true";
import { default as reviewsmVl7ctDwq_LADmGFBIhVkay_45YsnUCy9_jEjYC_JjLDEMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/reviews.vue?macro=true";
import { default as harvestsnaXXAIL772qQODFZtIGij9WNGIvHDcOZdRPAer9xpckMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/harvests.vue?macro=true";
import { default as index3EoLZDW_keSRqNnukwOOaVAxDGYtLsh6kYeAdm2eKyQMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index.vue?macro=true";
import { default as ChartModaldUsHcRds_45oxRU5_45n_45gGK3BDl4imQbSa_vELFN_45ZBOqMMeta } from "/app/pages/brands-manager/components/ChartModal.vue?macro=true";
import { default as TabBannersXyIXlqnlxlgZHaoeeH8rdVjSSgBm597cQHpBhuySS_45QMeta } from "/app/pages/brands-manager/components/TabBanners.vue?macro=true";
import { default as TabSchemesYcPmq2sT4gvAZzYYcYt4pFT7VauZYoV1PkVwvwsJ464Meta } from "/app/pages/brands-manager/components/TabSchemes.vue?macro=true";
import { default as TabTestersWhaoDmhvengZqkqf1EC_45wIY5GB8DxL9E9irWYsVCAS0Meta } from "/app/pages/brands-manager/components/TabTesters.vue?macro=true";
import { default as EditGrowBoxHHoNP6X_45ZIzoXz_45dqBmEZUOAoeOTSzd9lxCgeBJocEkMeta } from "/app/pages/brands-manager/components/EditGrowBox.vue?macro=true";
import { default as TabAccounts5IHHbwLh62YDTFQTojGqB_45R9kVL2n07gFwO5RhVuWGcMeta } from "/app/pages/brands-manager/components/TabAccounts.vue?macro=true";
import { default as TabProductsXlHeY4IBHb7T5MbvSJkZbnx1aXyHrgJ8mm7jWCwY960Meta } from "/app/pages/brands-manager/components/TabProducts.vue?macro=true";
import { default as EditNutrient8PWX4T5msDCdgmSBzVnQXFuOD1Q1_45KCdVYTjkcwvBEwMeta } from "/app/pages/brands-manager/components/EditNutrient.vue?macro=true";
import { default as indexxZFvEQc5Gm__45RZR_45INAJeFNwTQr8cL5TBCDxXjolXeAMeta } from "/app/pages/brands-manager/schemes/edit/[id]/index.vue?macro=true";
import { default as EditAirFilter0w_45_45vqZyimnET1OB5NuJfJsPURD9Ci3XuDqAOo_uWcwMeta } from "/app/pages/brands-manager/components/EditAirFilter.vue?macro=true";
import { default as EditBoxBannerOC1sjpLmA8MIRlnwiY7CESZ7MfWQmL9bpI9u6BIGTqYMeta } from "/app/pages/brands-manager/components/EditBoxBanner.vue?macro=true";
import { default as EditCpmBanner4LlS_8bbvFMWYpElMJBXMvTUEDfHFO73EqFEZu9KP5wMeta } from "/app/pages/brands-manager/components/EditCpmBanner.vue?macro=true";
import { default as TabStatisticsixJOUwbyhqiuTw3CxDfrv0ctx9K_45lvb_45iPiKasttQHIMeta } from "/app/pages/brands-manager/components/TabStatistics.vue?macro=true";
import { default as indexH74hPTz9UFX6RgzsOut4xEYjGKtB1fTZM6K43H4vwtIMeta } from "/app/pages/brands-manager/products/edit/[id]/index.vue?macro=true";
import { default as EditControllerehqxrHn_45FMAIK7fRV85nqit7bUMGJDCPyYB_avkXX1EMeta } from "/app/pages/brands-manager/components/EditController.vue?macro=true";
import { default as EditDripSystemEqf4OdIyHhH9FFJyWEO5RkFoNr_XuEuwG9vbmVKG4okMeta } from "/app/pages/brands-manager/components/EditDripSystem.vue?macro=true";
import { default as TabNewslettersSQk9HmXvk4gB1pcYM6x0TnOv8C2f0RY4YPOKffB6ZeUMeta } from "/app/pages/brands-manager/components/TabNewsletters.vue?macro=true";
import { default as TabSubscriptionDYGoow23f4SBv4gFNxQgtCzGNcEpbcnZAa6my0iptVIMeta } from "/app/pages/brands-manager/components/TabSubscription.vue?macro=true";
import { default as indexEDe9gNF9Fkt_45H2Qr0DDDRr0JnZwwTXbMWzbAg2trWLMMeta } from "/app/pages/brands-manager/banners/edit/box/[id]/index.vue?macro=true";
import { default as index91tLOmS_45YyWTx5tPz0JutPHZZ98bFxyRKHgJCCNDp2AMeta } from "/app/pages/brands-manager/banners/edit/cpm/[id]/index.vue?macro=true";
import { default as ChartNewslettersPWmVlbmVqH1YjgVYWuM8s0_45_w3q3cSkEFmSnJipaY_kMeta } from "/app/pages/brands-manager/components/ChartNewsletters.vue?macro=true";
import { default as EditCo2GeneratorNu0icEfvdGSis4av12MQJODI44UycUKbs3LhTMoY3MsMeta } from "/app/pages/brands-manager/components/EditCo2Generator.vue?macro=true";
import { default as EditHeaderBannerGrPA8k0U6r0ESVGqA8jG_7np2dBXMnZ1hdGm3tpYvZIMeta } from "/app/pages/brands-manager/components/EditHeaderBanner.vue?macro=true";
import { default as ChartPackageModalsWV8icibaD0D7O0119Q_4Yh4At_x1PsXIXatpdVkjz0Meta } from "/app/pages/brands-manager/components/ChartPackageModal.vue?macro=true";
import { default as EditAirConditionerJrJ70_32n47WG7Wjf8WhfDvRXfM4IIRiKL3AbUBQd7wMeta } from "/app/pages/brands-manager/components/EditAirConditioner.vue?macro=true";
import { default as EditNutrientSchemes33fOb6W2y_45wg6GDU9bo2jshyX9U2okBfUWYaQKZDfcMeta } from "/app/pages/brands-manager/components/EditNutrientScheme.vue?macro=true";
import { default as EditVentilationFancF6d1Na9QazJEZbgEl75mFMeoM7MEFe9fPEiOa3fLYoMeta } from "/app/pages/brands-manager/components/EditVentilationFan.vue?macro=true";
import { default as index86jcIQNWWS_45RP_450l46KjWHWm_4577sEBEeOoko3IsbLkIMeta } from "/app/pages/brands-manager/banners/edit/header/[id]/index.vue?macro=true";
import { default as EditHydroponicSystemgMHb47gLVxxfdev2Ojcwe9fVBZ0ujXIl7R_45sNtmyYYAMeta } from "/app/pages/brands-manager/components/EditHydroponicSystem.vue?macro=true";
import { default as EditNutrientCompositionwBrqre2lk3zr4yV_459NJB1Jw9Qjk4KdIU8xCSCJO3QowMeta } from "/app/pages/brands-manager/components/EditNutrientComposition.vue?macro=true";
import { default as indexlp4ByZepvEV_gO0MYg_45LbkVg8CW_7vqaOQ8IW6ODBC4Meta } from "/app/pages/brands-manager/products/edit_composition/[id]/index.vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "st",
    path: "/st",
    component: () => import("/app/pages/st.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/app/pages/terms.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/app/pages/policy.vue")
  },
  {
    name: "widget",
    path: "/widget",
    component: () => import("/app/pages/widget.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "partner",
    path: "/partner",
    component: () => import("/app/pages/partner.vue")
  },
  {
    name: "premium",
    path: "/premium",
    component: () => import("/app/pages/premium.vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: signinr_rYGcquUCtCe6UaCroPiKQxhVVecQ8xFrQzqubNzVsMeta || {},
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupsTWjhAgrrNIHjZDxwXTWWZ6X6mjwk7GNfKEQ1VBvxXYMeta || {},
    component: () => import("/app/pages/auth/signup.vue")
  },
  {
    name: "awards",
    path: "/awards",
    component: () => import("/app/pages/awards/index.vue")
  },
  {
    name: "shorts",
    path: "/shorts",
    component: () => import("/app/pages/shorts/index.vue")
  },
  {
    name: "auth-external",
    path: "/auth/external",
    meta: externalWMXQuMcbHLDgwrMkYyF3KqCAT_9f3z4LIFI1ASwnuPEMeta || {},
    component: () => import("/app/pages/auth/external.vue")
  },
  {
    name: "growers",
    path: "/growers",
    component: () => import("/app/pages/growers/index.vue")
  },
  {
    name: "journal",
    path: "/journal",
    component: () => import("/app/pages/journal/index.vue")
  },
  {
    name: "strains",
    path: "/strains",
    component: () => import("/app/pages/strains/index.vue")
  },
  {
    name: "seedbank",
    path: "/seedbank",
    component: () => import("/app/pages/seedbank/index.vue")
  },
  {
    name: "seeds-tag",
    path: "/seeds/:tag(.*)*",
    component: () => import("/app/pages/seeds/[...tag].vue")
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/app/pages/equipment/index.vue")
  },
  {
    name: "giveaways",
    path: "/giveaways",
    component: () => import("/app/pages/giveaways/index.vue")
  },
  {
    name: "nutrients",
    path: "/nutrients",
    component: () => import("/app/pages/nutrients/index.vue")
  },
  {
    name: "auth-unsubscribe",
    path: "/auth/unsubscribe",
    meta: unsubscribe3Yl0fI6xzOf4znDQWHGiAYKJJWhQYKJyoJv2A6FtxVAMeta || {},
    component: () => import("/app/pages/auth/unsubscribe.vue")
  },
  {
    name: "explore-tag",
    path: "/explore/:tag(.*)*",
    component: () => import("/app/pages/explore/[...tag].vue")
  },
  {
    name: "grow-boxes",
    path: "/grow-boxes",
    component: () => import("/app/pages/grow-boxes/index.vue")
  },
  {
    name: "grow-tents",
    path: "/grow-tents",
    component: () => import("/app/pages/grow-tents/index.vue")
  },
  {
    name: "shorts-edit-id",
    path: "/shorts/edit/:id()",
    component: () => import("/app/pages/shorts/edit/[id].vue")
  },
  {
    name: "substrates",
    path: "/substrates",
    component: () => import("/app/pages/substrates/index.vue")
  },
  {
    name: "video-id",
    path: "/video/:id()",
    meta: indexEdP4F0twbyewgpDKW2bZIOFLfFaG40gKSLqsXq_gEhUMeta || {},
    component: () => import("/app/pages/video/[id]/index.vue")
  },
  {
    name: "air-filters",
    path: "/air-filters",
    component: () => import("/app/pages/air-filters/index.vue")
  },
  {
    name: "controllers",
    path: "/controllers",
    component: () => import("/app/pages/controllers/index.vue")
  },
  {
    name: "diaries-edit-id",
    path: "/diaries/edit/:id()",
    component: () => import("/app/pages/diaries/edit/[id].vue")
  },
  {
    name: "grow-lights",
    path: "/grow-lights",
    component: () => import("/app/pages/grow-lights/index.vue")
  },
  {
    name: indexG7ZQo7DfQ_45qkhEhoBUdBi0qNC8oOF5iM2bMbs_aw5mkMeta?.name,
    path: "/grower/:id()",
    component: () => import("/app/pages/grower/[id]/index.vue"),
    children: [
  {
    name: "grower-id-index",
    path: "",
    component: () => import("/app/pages/grower/[id]/index/index.vue")
  },
  {
    name: "grower-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grower/[id]/index/diaries.vue")
  },
  {
    name: "grower-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grower/[id]/index/reviews.vue")
  },
  {
    name: "grower-id-index-activity",
    path: "activity",
    component: () => import("/app/pages/grower/[id]/index/activity.vue")
  },
  {
    name: "grower-id-index-followers",
    path: "followers",
    component: () => import("/app/pages/grower/[id]/index/followers.vue")
  },
  {
    name: "grower-id-index-following",
    path: "following",
    component: () => import("/app/pages/grower/[id]/index/following.vue")
  },
  {
    name: "grower-id-index-shorts",
    path: "shorts",
    component: () => import("/app/pages/grower/[id]/index/shorts/index.vue")
  }
]
  },
  {
    name: "shorts-id",
    path: "/shorts/:id()",
    component: () => import("/app/pages/shorts/[id]/index.vue")
  },
  {
    name: "auth-confirm-email",
    path: "/auth/confirm-email",
    meta: confirm_45emailzV_rfI7k9jea8K2qyoH2Vls4MI67frN2bOwDmuBgQvEMeta || {},
    component: () => import("/app/pages/auth/confirm-email.vue")
  },
  {
    name: indexc8z8zB12V_UtG6nbUuUDBo5byy18r_451xxVgaOuXiX2EMeta?.name,
    path: "/diaries/:id()",
    component: () => import("/app/pages/diaries/[id]/index.vue"),
    children: [
  {
    name: "diaries-id-index",
    path: "",
    component: () => import("/app/pages/diaries/[id]/index/index.vue")
  },
  {
    name: "diaries-id-index-week-week",
    path: "week/:week()",
    component: () => import("/app/pages/diaries/[id]/index/week/[week].vue")
  },
  {
    name: "diaries-id-index-edit-week-week",
    path: "edit/week/:week()",
    component: () => import("/app/pages/diaries/[id]/index/edit/week/[week].vue")
  },
  {
    name: "diaries-id-index-edit-smoke-week",
    path: "edit/smoke/:week()",
    component: () => import("/app/pages/diaries/[id]/index/edit/smoke/[week].vue")
  },
  {
    name: "diaries-id-index-weeks-week-edit",
    path: "weeks/:week()/edit",
    component: () => import("/app/pages/diaries/[id]/index/weeks/[week]/edit.vue")
  },
  {
    name: "diaries-id-index-weeks-week-smoke",
    path: "weeks/:week()/smoke",
    component: () => import("/app/pages/diaries/[id]/index/weeks/[week]/smoke.vue")
  }
]
  },
  {
    name: "drip-systems",
    path: "/drip-systems",
    component: () => import("/app/pages/drip-systems/index.vue")
  },
  {
    name: indexhBF5nXiBQtIRtOJkoiXgJZE_3Zae_KefXiTxml18oAMMeta?.name,
    path: "/strains/:id()",
    component: () => import("/app/pages/strains/[id]/index.vue"),
    children: [
  {
    name: "strains-id-index",
    path: "",
    component: () => import("/app/pages/strains/[id]/index/index.vue")
  },
  {
    name: "strains-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/strains/[id]/index/diaries.vue")
  },
  {
    name: "strains-id-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/strains/[id]/index/gallery.vue")
  },
  {
    name: "strains-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/strains/[id]/index/growers.vue")
  },
  {
    name: "strains-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/strains/[id]/index/reviews.vue")
  },
  {
    name: "strains-id-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/strains/[id]/index/harvests.vue")
  },
  {
    name: "strains-id-index-products",
    path: "products",
    component: () => import("/app/pages/strains/[id]/index/products.vue")
  }
]
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordNpUsiQybcTLi_45Ke3wh24gyldOA9IXhGjC1yM32hGDDMMeta || {},
    component: () => import("/app/pages/auth/reset-password.vue")
  },
  {
    name: "awards-year",
    path: "/awards/:year()",
    component: () => import("/app/pages/awards/[year]/index.vue")
  },
  {
    name: "grow-journals",
    path: "/grow-journals",
    component: () => import("/app/pages/grow-journals/index.vue")
  },
  {
    name: "journal-var",
    path: "/journal/:var()",
    component: () => import("/app/pages/journal/[var]/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/app/pages/notifications/index.vue")
  },
  {
    name: indexMaHcNiJ7BD34uquYcLtDROwerAPfLj3lKlqba_45wioXMMeta?.name,
    path: "/seedbank/:id()",
    component: () => import("/app/pages/seedbank/[id]/index.vue"),
    children: [
  {
    name: "seedbank-id-index",
    path: "",
    component: () => import("/app/pages/seedbank/[id]/index/index.vue")
  },
  {
    name: "seedbank-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/seedbank/[id]/index/diaries.vue")
  },
  {
    name: "seedbank-id-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/seedbank/[id]/index/gallery.vue")
  },
  {
    name: "seedbank-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/seedbank/[id]/index/growers.vue")
  },
  {
    name: "seedbank-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/seedbank/[id]/index/reviews.vue")
  },
  {
    name: "seedbank-id-index-strains",
    path: "strains",
    component: () => import("/app/pages/seedbank/[id]/index/strains.vue")
  },
  {
    name: "seedbank-id-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/seedbank/[id]/index/harvests.vue")
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordCSWbKn9J3J_B220JzyKrtw7YYrymJGKEwS0HPhIK87AMeta || {},
    component: () => import("/app/pages/auth/forgot-password.vue")
  },
  {
    name: index4TD_DHOlqni2bIO52YuclREy4uL22KKGTS7S7aFDQMUMeta?.name,
    path: "/brands-manager",
    component: () => import("/app/pages/brands-manager/index.vue"),
    children: [
  {
    name: "brands-manager-index-edit",
    path: "edit",
    component: () => import("/app/pages/brands-manager/index/edit.vue")
  },
  {
    name: "brands-manager-index",
    path: "",
    component: () => import("/app/pages/brands-manager/index/index.vue")
  },
  {
    name: "brands-manager-index-banners",
    path: "banners",
    component: () => import("/app/pages/brands-manager/index/banners.vue")
  },
  {
    name: "brands-manager-index-schemes",
    path: "schemes",
    component: () => import("/app/pages/brands-manager/index/schemes.vue")
  },
  {
    name: "brands-manager-index-testers",
    path: "testers",
    component: () => import("/app/pages/brands-manager/index/testers.vue")
  },
  {
    name: "brands-manager-index-welcome",
    path: "welcome",
    component: () => import("/app/pages/brands-manager/index/welcome.vue")
  },
  {
    name: "brands-manager-index-products",
    path: "products",
    component: () => import("/app/pages/brands-manager/index/products.vue")
  },
  {
    name: "brands-manager-index-statistics",
    path: "statistics",
    component: () => import("/app/pages/brands-manager/index/statistics.vue")
  },
  {
    name: "brands-manager-index-newsletters",
    path: "newsletters",
    component: () => import("/app/pages/brands-manager/index/newsletters.vue")
  },
  {
    name: "brands-manager-index-welcome-old",
    path: "welcome-old",
    component: () => import("/app/pages/brands-manager/index/welcome-old.vue")
  },
  {
    name: "brands-manager-index-subscription",
    path: "subscription",
    component: () => import("/app/pages/brands-manager/index/subscription.vue")
  }
]
  },
  {
    name: "co2-generators",
    path: "/co2-generators",
    component: () => import("/app/pages/co2-generators/index.vue")
  },
  {
    name: indexZ4Q3DnG_45VCeOoBHDjV0CejnkcNnPexnMWXmzFdyIMvkMeta?.name,
    path: "/equipment/:id()",
    component: () => import("/app/pages/equipment/[id]/index.vue"),
    children: [
  {
    name: "equipment-id-index",
    path: "",
    component: () => import("/app/pages/equipment/[id]/index/index.vue")
  },
  {
    name: "equipment-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/equipment/[id]/index/diaries.vue")
  },
  {
    name: "equipment-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/equipment/[id]/index/growers.vue")
  },
  {
    name: "equipment-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/equipment/[id]/index/reviews.vue")
  },
  {
    name: "equipment-id-index-products",
    path: "products",
    component: () => import("/app/pages/equipment/[id]/index/products.vue")
  }
]
  },
  {
    name: "journal-voting",
    path: "/journal/voting",
    component: () => import("/app/pages/journal/voting/index.vue")
  },
  {
    name: indexSbYznR0gEYPQrbxeGxvm7GiRgJtmaloGgK_45u19kArT0Meta?.name,
    path: "/nutrients/:id()",
    component: () => import("/app/pages/nutrients/[id]/index.vue"),
    children: [
  {
    name: "nutrients-id-index",
    path: "",
    component: () => import("/app/pages/nutrients/[id]/index/index.vue")
  },
  {
    name: "nutrients-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/nutrients/[id]/index/diaries.vue")
  },
  {
    name: "nutrients-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/nutrients/[id]/index/growers.vue")
  },
  {
    name: "nutrients-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/nutrients/[id]/index/reviews.vue")
  },
  {
    name: "nutrients-id-index-schemes",
    path: "schemes",
    component: () => import("/app/pages/nutrients/[id]/index/schemes.vue")
  },
  {
    name: "nutrients-id-index-products",
    path: "products",
    component: () => import("/app/pages/nutrients/[id]/index/products.vue")
  }
]
  },
  {
    name: "giveaways-var",
    path: "/giveaways/:var()",
    component: () => import("/app/pages/giveaways/[var]/index.vue")
  },
  {
    name: indexQNN_wMnh0ijoo9UKUWQw7HHca5p3ZnTkCZEjr7zx2LYMeta?.name,
    path: "/grow-boxes/:id()",
    component: () => import("/app/pages/grow-boxes/[id]/index.vue"),
    children: [
  {
    name: "grow-boxes-id-index",
    path: "",
    component: () => import("/app/pages/grow-boxes/[id]/index/index.vue")
  },
  {
    name: "grow-boxes-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-boxes/[id]/index/diaries.vue")
  },
  {
    name: "grow-boxes-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-boxes/[id]/index/growers.vue")
  },
  {
    name: "grow-boxes-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-boxes/[id]/index/reviews.vue")
  },
  {
    name: "grow-boxes-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-boxes/[id]/index/products.vue")
  }
]
  },
  {
    name: index6JnImZ8P_D88PKmuWjBrUGa3hUOoFEgY6wR8iF_yP_UMeta?.name,
    path: "/grow-tents/:id()",
    component: () => import("/app/pages/grow-tents/[id]/index.vue"),
    children: [
  {
    name: "grow-tents-id-index",
    path: "",
    component: () => import("/app/pages/grow-tents/[id]/index/index.vue")
  },
  {
    name: "grow-tents-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-tents/[id]/index/diaries.vue")
  },
  {
    name: "grow-tents-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-tents/[id]/index/growers.vue")
  },
  {
    name: "grow-tents-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-tents/[id]/index/reviews.vue")
  },
  {
    name: "grow-tents-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-tents/[id]/index/products.vue")
  }
]
  },
  {
    name: indexiakswyEv6n_zTxoEUEpSrTF8gFJahJTuum7BnGleqScMeta?.name,
    path: "/substrates/:id()",
    component: () => import("/app/pages/substrates/[id]/index.vue"),
    children: [
  {
    name: "substrates-id-index",
    path: "",
    component: () => import("/app/pages/substrates/[id]/index/index.vue")
  },
  {
    name: "substrates-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/substrates/[id]/index/diaries.vue")
  },
  {
    name: "substrates-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/substrates/[id]/index/growers.vue")
  },
  {
    name: "substrates-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/substrates/[id]/index/reviews.vue")
  },
  {
    name: "substrates-id-index-products",
    path: "products",
    component: () => import("/app/pages/substrates/[id]/index/products.vue")
  }
]
  },
  {
    name: "air-conditioners",
    path: "/air-conditioners",
    component: () => import("/app/pages/air-conditioners/index.vue")
  },
  {
    name: indexnoR6X3OU8v4T_45u_45PAfd40war2NdcRzbQ2AzbPGOfpdMMeta?.name,
    path: "/air-filters/:id()",
    component: () => import("/app/pages/air-filters/[id]/index.vue"),
    children: [
  {
    name: "air-filters-id-index",
    path: "",
    component: () => import("/app/pages/air-filters/[id]/index/index.vue")
  },
  {
    name: "air-filters-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-filters/[id]/index/diaries.vue")
  },
  {
    name: "air-filters-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-filters/[id]/index/growers.vue")
  },
  {
    name: "air-filters-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-filters/[id]/index/reviews.vue")
  },
  {
    name: "air-filters-id-index-products",
    path: "products",
    component: () => import("/app/pages/air-filters/[id]/index/products.vue")
  }
]
  },
  {
    name: indexJGE_G5Zu2XoOWPjKKjsydVOmQyTw8r6nOOXbsx_451UCYMeta?.name,
    path: "/controllers/:id()",
    component: () => import("/app/pages/controllers/[id]/index.vue"),
    children: [
  {
    name: "controllers-id-index",
    path: "",
    component: () => import("/app/pages/controllers/[id]/index/index.vue")
  },
  {
    name: "controllers-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/controllers/[id]/index/diaries.vue")
  },
  {
    name: "controllers-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/controllers/[id]/index/growers.vue")
  },
  {
    name: "controllers-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/controllers/[id]/index/reviews.vue")
  },
  {
    name: "controllers-id-index-products",
    path: "products",
    component: () => import("/app/pages/controllers/[id]/index/products.vue")
  }
]
  },
  {
    name: "external2-rating",
    path: "/external2/rating",
    meta: index_45m0r2F1hzb_fmfb_45pm98lQIU7CHnT8LsSKS_k2anO_0Meta || {},
    component: () => import("/app/pages/external2/rating/index.vue")
  },
  {
    name: indexXlUQwFKd2YvnpyWMEhqTLj4nP71r2g_45BuK_TmXAQkcoMeta?.name,
    path: "/grow-lights/:id()",
    component: () => import("/app/pages/grow-lights/[id]/index.vue"),
    children: [
  {
    name: "grow-lights-id-index",
    path: "",
    component: () => import("/app/pages/grow-lights/[id]/index/index.vue")
  },
  {
    name: "grow-lights-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-lights/[id]/index/diaries.vue")
  },
  {
    name: "grow-lights-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-lights/[id]/index/growers.vue")
  },
  {
    name: "grow-lights-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-lights/[id]/index/reviews.vue")
  },
  {
    name: "grow-lights-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-lights/[id]/index/products.vue")
  }
]
  },
  {
    name: "ventilation-fans",
    path: "/ventilation-fans",
    component: () => import("/app/pages/ventilation-fans/index.vue")
  },
  {
    name: indexT42EULBix_fMug4wRagVFfB5xxHXCZV4N8ktoDhPDioMeta?.name,
    path: "/drip-systems/:id()",
    component: () => import("/app/pages/drip-systems/[id]/index.vue"),
    children: [
  {
    name: "drip-systems-id-index",
    path: "",
    component: () => import("/app/pages/drip-systems/[id]/index/index.vue")
  },
  {
    name: "drip-systems-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/drip-systems/[id]/index/diaries.vue")
  },
  {
    name: "drip-systems-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/drip-systems/[id]/index/growers.vue")
  },
  {
    name: "drip-systems-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/drip-systems/[id]/index/reviews.vue")
  },
  {
    name: "drip-systems-id-index-products",
    path: "products",
    component: () => import("/app/pages/drip-systems/[id]/index/products.vue")
  }
]
  },
  {
    name: "grow-questions-tag",
    path: "/grow-questions/:tag(.*)*",
    component: () => import("/app/pages/grow-questions/[...tag].vue")
  },
  {
    name: "brands-manager-func-utils",
    path: "/brands-manager/func/utils",
    component: () => import("/app/pages/brands-manager/func/utils.js")
  },
  {
    name: "brands-manager-view-Head",
    path: "/brands-manager/view/Head",
    component: () => import("/app/pages/brands-manager/view/Head.vue")
  },
  {
    name: "grow-questions-edit-id",
    path: "/grow-questions/edit/:id()",
    component: () => import("/app/pages/grow-questions/edit/[id].vue")
  },
  {
    name: "hydroponic-systems",
    path: "/hydroponic-systems",
    component: () => import("/app/pages/hydroponic-systems/index.vue")
  },
  {
    name: "journal-tags-var",
    path: "/journal/tags/:var()",
    component: () => import("/app/pages/journal/tags/[var]/index.vue")
  },
  {
    name: indexjas_7KqjKoZc5z25Wm3OcuBPVJvBX98sMfJhaxC6wA8Meta?.name,
    path: "/co2-generators/:id()",
    component: () => import("/app/pages/co2-generators/[id]/index.vue"),
    children: [
  {
    name: "co2-generators-id-index",
    path: "",
    component: () => import("/app/pages/co2-generators/[id]/index/index.vue")
  },
  {
    name: "co2-generators-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/co2-generators/[id]/index/diaries.vue")
  },
  {
    name: "co2-generators-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/co2-generators/[id]/index/growers.vue")
  },
  {
    name: "co2-generators-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/co2-generators/[id]/index/reviews.vue")
  },
  {
    name: "co2-generators-id-index-products",
    path: "products",
    component: () => import("/app/pages/co2-generators/[id]/index/products.vue")
  }
]
  },
  {
    name: "grow-journals-strain",
    path: "/grow-journals/:strain(.*)*",
    component: () => import("/app/pages/grow-journals/[...strain].vue")
  },
  {
    name: "grow-questions-id",
    path: "/grow-questions/:id()",
    component: () => import("/app/pages/grow-questions/[id]/index.vue")
  },
  {
    name: indexU6nD4QzkI8fXm09ZrM_HO0Mzh09hYg77UhKBHznQIJsMeta?.name,
    path: "/seedbank/:id()/:id2()",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index.vue"),
    children: [
  {
    name: "seedbank-id-id2-index",
    path: "",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/index.vue")
  },
  {
    name: "seedbank-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "seedbank-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "seedbank-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/growers.vue")
  },
  {
    name: "seedbank-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "seedbank-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexvZrwBuTkk0hzeKTlAXDZFixbEH_456JDODmxPgxuug4gYMeta?.name,
    path: "/equipment/:id()/:id2()",
    component: () => import("/app/pages/equipment/[id]/[id2]/index.vue"),
    children: [
  {
    name: "equipment-id-id2-index",
    path: "",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/index.vue")
  },
  {
    name: "equipment-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "equipment-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "equipment-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/growers.vue")
  },
  {
    name: "equipment-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "equipment-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexhDhvRD3qcDbPSW8TrDMQUb_Vyc2Su24tn2cLP0uFO4wMeta?.name,
    path: "/grower/:id()/settings",
    component: () => import("/app/pages/grower/[id]/settings/index.vue"),
    children: [
  {
    name: "grower-id-settings-index",
    path: "",
    component: () => import("/app/pages/grower/[id]/settings/index/index.vue")
  },
  {
    name: "grower-id-settings-index-banned",
    path: "banned",
    component: () => import("/app/pages/grower/[id]/settings/index/banned.vue")
  },
  {
    name: "grower-id-settings-index-delete",
    path: "delete",
    component: () => import("/app/pages/grower/[id]/settings/index/delete.vue")
  },
  {
    name: "grower-id-settings-index-cookies",
    path: "cookies",
    component: () => import("/app/pages/grower/[id]/settings/index/cookies.vue")
  },
  {
    name: "grower-id-settings-index-socials",
    path: "socials",
    component: () => import("/app/pages/grower/[id]/settings/index/socials.vue")
  },
  {
    name: "grower-id-settings-index-password",
    path: "password",
    component: () => import("/app/pages/grower/[id]/settings/index/password.vue")
  }
]
  },
  {
    name: "journal-voting-var",
    path: "/journal/voting/:var()",
    component: () => import("/app/pages/journal/voting/[var]/index.vue")
  },
  {
    name: indexzEhXhEh9tNDkbRZ0ii33OmadVtF53N25PL0ujeDQg24Meta?.name,
    path: "/nutrients/:id()/:id2()",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index.vue"),
    children: [
  {
    name: "nutrients-id-id2-index",
    path: "",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/index.vue")
  },
  {
    name: "nutrients-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "nutrients-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "nutrients-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/growers.vue")
  },
  {
    name: "nutrients-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "nutrients-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexc6Sk5jyYBOAWw5psLKrXSQt6GRpwie39D8A3u6osPuUMeta?.name,
    path: "/air-conditioners/:id()",
    component: () => import("/app/pages/air-conditioners/[id]/index.vue"),
    children: [
  {
    name: "air-conditioners-id-index",
    path: "",
    component: () => import("/app/pages/air-conditioners/[id]/index/index.vue")
  },
  {
    name: "air-conditioners-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-conditioners/[id]/index/diaries.vue")
  },
  {
    name: "air-conditioners-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-conditioners/[id]/index/growers.vue")
  },
  {
    name: "air-conditioners-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-conditioners/[id]/index/reviews.vue")
  },
  {
    name: "air-conditioners-id-index-products",
    path: "products",
    component: () => import("/app/pages/air-conditioners/[id]/index/products.vue")
  }
]
  },
  {
    name: indexUUobWqmvi13x61WIcd218SRKfwCFfW4mvc4wowsxEJMMeta?.name,
    path: "/grow-boxes/:id()/:id2()",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-boxes-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-boxes-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-boxes-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-boxes-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-boxes-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "grow-boxes-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexwe46edHeTapP_FUcmB39mPOxXH_45Vd_e3UuvXipVj62gMeta?.name,
    path: "/grow-tents/:id()/:id2()",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-tents-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-tents-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-tents-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-tents-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-tents-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "grow-tents-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexLMnnWdco_RcE8weAQMwZbbqMNoBgvHQA32u_j7sdQkkMeta?.name,
    path: "/substrates/:id()/:id2()",
    component: () => import("/app/pages/substrates/[id]/[id2]/index.vue"),
    children: [
  {
    name: "substrates-id-id2-index",
    path: "",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/index.vue")
  },
  {
    name: "substrates-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "substrates-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "substrates-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/growers.vue")
  },
  {
    name: "substrates-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "substrates-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/substrates/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexuJ1BcJQkbEvaJOR_f91191XbXZyyr54xNuNThCWpRDsMeta?.name,
    path: "/ventilation-fans/:id()",
    component: () => import("/app/pages/ventilation-fans/[id]/index.vue"),
    children: [
  {
    name: "ventilation-fans-id-index",
    path: "",
    component: () => import("/app/pages/ventilation-fans/[id]/index/index.vue")
  },
  {
    name: "ventilation-fans-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/ventilation-fans/[id]/index/diaries.vue")
  },
  {
    name: "ventilation-fans-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/ventilation-fans/[id]/index/growers.vue")
  },
  {
    name: "ventilation-fans-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/ventilation-fans/[id]/index/reviews.vue")
  },
  {
    name: "ventilation-fans-id-index-products",
    path: "products",
    component: () => import("/app/pages/ventilation-fans/[id]/index/products.vue")
  }
]
  },
  {
    name: indexSglm17G0yGOSNPZmzmdRhr8FmwWLUyczKn3Gb1zmiWgMeta?.name,
    path: "/air-filters/:id()/:id2()",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index.vue"),
    children: [
  {
    name: "air-filters-id-id2-index",
    path: "",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/index.vue")
  },
  {
    name: "air-filters-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "air-filters-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "air-filters-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/growers.vue")
  },
  {
    name: "air-filters-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "air-filters-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "brands-manager-func-editBrand",
    path: "/brands-manager/func/editBrand",
    component: () => import("/app/pages/brands-manager/func/editBrand.js")
  },
  {
    name: indexNdDudvgj9IQyJ9eF_Xc_45wCxe_45IbKF0YQakj0_45N60ZdcMeta?.name,
    path: "/controllers/:id()/:id2()",
    component: () => import("/app/pages/controllers/[id]/[id2]/index.vue"),
    children: [
  {
    name: "controllers-id-id2-index",
    path: "",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/index.vue")
  },
  {
    name: "controllers-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "controllers-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "controllers-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/growers.vue")
  },
  {
    name: "controllers-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "controllers-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexDLpUBjpPyDQ8QVIBjHcdpjTFS7g0BtCuM1YqjTjbqmAMeta?.name,
    path: "/grow-lights/:id()/:id2()",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-lights-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-lights-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-lights-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-lights-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-lights-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "grow-lights-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "journal-category-var",
    path: "/journal/category/:var()",
    component: () => import("/app/pages/journal/category/[var]/index.vue")
  },
  {
    name: "brands-manager-func-editBanner",
    path: "/brands-manager/func/editBanner",
    component: () => import("/app/pages/brands-manager/func/editBanner.js")
  },
  {
    name: "brands-manager-func-editScheme",
    path: "/brands-manager/func/editScheme",
    component: () => import("/app/pages/brands-manager/func/editScheme.js")
  },
  {
    name: indexIoMfhDCGeh6dvgB0qDexiW_45HXUEllb93LYxdN04UlWQMeta?.name,
    path: "/drip-systems/:id()/:id2()",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index.vue"),
    children: [
  {
    name: "drip-systems-id-id2-index",
    path: "",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/index.vue")
  },
  {
    name: "drip-systems-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "drip-systems-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "drip-systems-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/growers.vue")
  },
  {
    name: "drip-systems-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "drip-systems-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: indexwsDzvbX2eWV8LLGn_45eLSpzx_wxzjnCOVTZZeKRP7CngMeta?.name,
    path: "/hydroponic-systems/:id()",
    component: () => import("/app/pages/hydroponic-systems/[id]/index.vue"),
    children: [
  {
    name: "hydroponic-systems-id-index",
    path: "",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/index.vue")
  },
  {
    name: "hydroponic-systems-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/diaries.vue")
  },
  {
    name: "hydroponic-systems-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/growers.vue")
  },
  {
    name: "hydroponic-systems-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/reviews.vue")
  },
  {
    name: "hydroponic-systems-id-index-products",
    path: "products",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/products.vue")
  }
]
  },
  {
    name: "brands-manager-func-editProduct",
    path: "/brands-manager/func/editProduct",
    component: () => import("/app/pages/brands-manager/func/editProduct.js")
  },
  {
    name: "brands-manager-components-Chart",
    path: "/brands-manager/components/Chart",
    component: () => import("/app/pages/brands-manager/components/Chart.vue")
  },
  {
    name: index4vToBULVzgOsbP4MfB0hIcZCk7hidL5H8lvJbsBS378Meta?.name,
    path: "/co2-generators/:id()/:id2()",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index.vue"),
    children: [
  {
    name: "co2-generators-id-id2-index",
    path: "",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/index.vue")
  },
  {
    name: "co2-generators-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "co2-generators-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "co2-generators-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/growers.vue")
  },
  {
    name: "co2-generators-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "co2-generators-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "brands-manager-components-Select",
    path: "/brands-manager/components/Select",
    component: () => import("/app/pages/brands-manager/components/Select.vue")
  },
  {
    name: indexWzcdTOPEH_V8qilT_eoaM0aw_SMyDesAjH_oyJ36tmIMeta?.name,
    path: "/air-conditioners/:id()/:id2()",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index.vue"),
    children: [
  {
    name: "air-conditioners-id-id2-index",
    path: "",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/index.vue")
  },
  {
    name: "air-conditioners-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "air-conditioners-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "air-conditioners-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/growers.vue")
  },
  {
    name: "air-conditioners-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "air-conditioners-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "strains-category-category-val",
    path: "/strains/category/:category()/:val()",
    component: () => import("/app/pages/strains/category/[category]/[val].vue")
  },
  {
    name: indexGxVfIKXN_usRt435QWA7Pe954bJGHS_45A9crRdnyhTDkMeta?.name,
    path: "/ventilation-fans/:id()/:id2()",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index.vue"),
    children: [
  {
    name: "ventilation-fans-id-id2-index",
    path: "",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/index.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/growers.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "brands-manager-components-EditLamp",
    path: "/brands-manager/components/EditLamp",
    component: () => import("/app/pages/brands-manager/components/EditLamp.vue")
  },
  {
    name: "brands-manager-components-EditSeed",
    path: "/brands-manager/components/EditSeed",
    component: () => import("/app/pages/brands-manager/components/EditSeed.vue")
  },
  {
    name: "brands-manager-components-EditTent",
    path: "/brands-manager/components/EditTent",
    component: () => import("/app/pages/brands-manager/components/EditTent.vue")
  },
  {
    name: "brands-manager-components-EditBrand",
    path: "/brands-manager/components/EditBrand",
    component: () => import("/app/pages/brands-manager/components/EditBrand.vue")
  },
  {
    name: index3EoLZDW_keSRqNnukwOOaVAxDGYtLsh6kYeAdm2eKyQMeta?.name,
    path: "/hydroponic-systems/:id()/:id2()",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index.vue"),
    children: [
  {
    name: "hydroponic-systems-id-id2-index",
    path: "",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/index.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/growers.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/reviews.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/harvests.vue")
  }
]
  },
  {
    name: "brands-manager-components-ChartModal",
    path: "/brands-manager/components/ChartModal",
    component: () => import("/app/pages/brands-manager/components/ChartModal.vue")
  },
  {
    name: "brands-manager-components-TabBanners",
    path: "/brands-manager/components/TabBanners",
    component: () => import("/app/pages/brands-manager/components/TabBanners.vue")
  },
  {
    name: "brands-manager-components-TabSchemes",
    path: "/brands-manager/components/TabSchemes",
    component: () => import("/app/pages/brands-manager/components/TabSchemes.vue")
  },
  {
    name: "brands-manager-components-TabTesters",
    path: "/brands-manager/components/TabTesters",
    component: () => import("/app/pages/brands-manager/components/TabTesters.vue")
  },
  {
    name: "brands-manager-components-EditGrowBox",
    path: "/brands-manager/components/EditGrowBox",
    component: () => import("/app/pages/brands-manager/components/EditGrowBox.vue")
  },
  {
    name: "brands-manager-components-TabAccounts",
    path: "/brands-manager/components/TabAccounts",
    component: () => import("/app/pages/brands-manager/components/TabAccounts.vue")
  },
  {
    name: "brands-manager-components-TabProducts",
    path: "/brands-manager/components/TabProducts",
    component: () => import("/app/pages/brands-manager/components/TabProducts.vue")
  },
  {
    name: "brands-manager-components-EditNutrient",
    path: "/brands-manager/components/EditNutrient",
    component: () => import("/app/pages/brands-manager/components/EditNutrient.vue")
  },
  {
    name: "brands-manager-schemes-edit-id",
    path: "/brands-manager/schemes/edit/:id()",
    component: () => import("/app/pages/brands-manager/schemes/edit/[id]/index.vue")
  },
  {
    name: "brands-manager-components-EditAirFilter",
    path: "/brands-manager/components/EditAirFilter",
    component: () => import("/app/pages/brands-manager/components/EditAirFilter.vue")
  },
  {
    name: "brands-manager-components-EditBoxBanner",
    path: "/brands-manager/components/EditBoxBanner",
    component: () => import("/app/pages/brands-manager/components/EditBoxBanner.vue")
  },
  {
    name: "brands-manager-components-EditCpmBanner",
    path: "/brands-manager/components/EditCpmBanner",
    component: () => import("/app/pages/brands-manager/components/EditCpmBanner.vue")
  },
  {
    name: "brands-manager-components-TabStatistics",
    path: "/brands-manager/components/TabStatistics",
    component: () => import("/app/pages/brands-manager/components/TabStatistics.vue")
  },
  {
    name: "brands-manager-products-edit-id",
    path: "/brands-manager/products/edit/:id()",
    component: () => import("/app/pages/brands-manager/products/edit/[id]/index.vue")
  },
  {
    name: "brands-manager-components-EditController",
    path: "/brands-manager/components/EditController",
    component: () => import("/app/pages/brands-manager/components/EditController.vue")
  },
  {
    name: "brands-manager-components-EditDripSystem",
    path: "/brands-manager/components/EditDripSystem",
    component: () => import("/app/pages/brands-manager/components/EditDripSystem.vue")
  },
  {
    name: "brands-manager-components-TabNewsletters",
    path: "/brands-manager/components/TabNewsletters",
    component: () => import("/app/pages/brands-manager/components/TabNewsletters.vue")
  },
  {
    name: "brands-manager-components-TabSubscription",
    path: "/brands-manager/components/TabSubscription",
    component: () => import("/app/pages/brands-manager/components/TabSubscription.vue")
  },
  {
    name: "brands-manager-banners-edit-box-id",
    path: "/brands-manager/banners/edit/box/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/box/[id]/index.vue")
  },
  {
    name: "brands-manager-banners-edit-cpm-id",
    path: "/brands-manager/banners/edit/cpm/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/cpm/[id]/index.vue")
  },
  {
    name: "brands-manager-components-ChartNewsletters",
    path: "/brands-manager/components/ChartNewsletters",
    component: () => import("/app/pages/brands-manager/components/ChartNewsletters.vue")
  },
  {
    name: "brands-manager-components-EditCo2Generator",
    path: "/brands-manager/components/EditCo2Generator",
    component: () => import("/app/pages/brands-manager/components/EditCo2Generator.vue")
  },
  {
    name: "brands-manager-components-EditHeaderBanner",
    path: "/brands-manager/components/EditHeaderBanner",
    component: () => import("/app/pages/brands-manager/components/EditHeaderBanner.vue")
  },
  {
    name: "brands-manager-components-ChartPackageModal",
    path: "/brands-manager/components/ChartPackageModal",
    component: () => import("/app/pages/brands-manager/components/ChartPackageModal.vue")
  },
  {
    name: "brands-manager-components-EditAirConditioner",
    path: "/brands-manager/components/EditAirConditioner",
    component: () => import("/app/pages/brands-manager/components/EditAirConditioner.vue")
  },
  {
    name: "brands-manager-components-EditNutrientScheme",
    path: "/brands-manager/components/EditNutrientScheme",
    component: () => import("/app/pages/brands-manager/components/EditNutrientScheme.vue")
  },
  {
    name: "brands-manager-components-EditVentilationFan",
    path: "/brands-manager/components/EditVentilationFan",
    component: () => import("/app/pages/brands-manager/components/EditVentilationFan.vue")
  },
  {
    name: "brands-manager-banners-edit-header-id",
    path: "/brands-manager/banners/edit/header/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/header/[id]/index.vue")
  },
  {
    name: "brands-manager-components-EditHydroponicSystem",
    path: "/brands-manager/components/EditHydroponicSystem",
    component: () => import("/app/pages/brands-manager/components/EditHydroponicSystem.vue")
  },
  {
    name: "brands-manager-components-EditNutrientComposition",
    path: "/brands-manager/components/EditNutrientComposition",
    component: () => import("/app/pages/brands-manager/components/EditNutrientComposition.vue")
  },
  {
    name: "brands-manager-products-edit_composition-id",
    path: "/brands-manager/products/edit_composition/:id()",
    component: () => import("/app/pages/brands-manager/products/edit_composition/[id]/index.vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/src/external/reviews/js/growdiaries.js",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/src/external/rating/js/growdiaries.js",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/seedbank/fastbuds/wedding-cheesecake",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/seedbank/kannabia/kritic-70-auto",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/seedbank/zkittlez",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/diaries/57665-royal-queen-seeds-quick-one-grow-journal-by-frisca01",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/diaries/52487-humboldt-seeds-bubba-cheese-auto-grow-journal-by-highndry",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/diaries/82626-stardawg-grapefruit-auto-grow-journal-by-vadibul",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]