import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  if (import.meta.client) {
    if (!useClientIsBot().value) {
      const { initialize } = useGtag()
      if (typeof initialize === 'function') {
        console.log('Gtag warmup')
        initialize()
      }
    }
  }
})